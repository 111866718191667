     /**
 * Created by forbiz on 2019-02-11.
 */


const login = () => {
    const $window = $(window);
    const $document = $(document);
    const $htmlbody = $('html, body');
    const $body = $('body');

         
     // 상세 대메뉴 탭 Event
     const loginlTnb = () => {
         
        $document
            .on('click', ".tab-list", function() {
                const $this = $(this);
                const $tnb_area = $this.closest('.tab-list');
                
                const tab_name = $this.data('tab');
                const $target = $('#' + tab_name);

                $this.addClass('tab-list--on').siblings().removeClass('tab-list--on');
                $target.addClass('tab-contents--current').siblings().removeClass('tab-contents--current');


                return false;
            });
    };

    const loginCheck = () => {
        const $loginChk = $('.checkbox-round input[type=checkbox]');

        $loginChk
            .on('change', function() {
                const $this = $(this);

                if ( $this.prop('checked') == true ) {
                    $this.attr('data-check', 'y');
                } else {
                    $this.attr('data-check', 'n');
                }
            });
    }

    const login_init = () => {
        loginlTnb();
        loginCheck();
    };

    login_init();

};

export default login;
     
     
     
     
     
     
     
     
     
     
