     /**
 * Created by forbiz on 2019-02-11.
 */


const eventCheckAttendance = () => {
    const $window = $(window);
    const $document = $(document);
    const $htmlbody = $('html, body');
    const $body = $('body');

    const calendarJs = () => {
        const $tbody = $('.cal_day');
        const fix_year = parseInt( '2019', 10 );
        const fix_month = parseInt( '6', 10 );
        const fix_day = parseInt( '1', 10 );
        const fix_today = new Date( fix_year, fix_month-1, fix_day ); // 오늘 고정 일자
        let year = fix_year; // 년 (변동데이터) 
        let month = fix_month; // 월 (변동데이터)
        let today = fix_today.getDate(); // 오늘 일자(변동데이터)
        let week_today = fix_today.getDay(); // 오늘 요일(변동데이터)
        let first_day = new Date( year, month-1, 1 ); // 해당 년, 월 1일 (변동데이터)
        let last_day = new Date( year, month, 0 ); // 해당 년, 월 마지막일 (변동데이터)
        let str = '';
        
            const calendarBuild = () => {        
                for ( let rows = 0; rows < 6 ; rows ++ ) {
                    str += '<tr>';
        
                    for ( let cols = 0; cols < 7; cols++ ) {
        
                        if ( cols < week_today && rows == 0 ) {  
                            if ( first_day.getDate() - week_today + cols < 1 ) {
                                calendarOutput( year, month, today, false );
                                
                            } else {
                                today = first_day.getDate() - week_today + cols;
                            }
                            
                        } else {
                            if ( today <= last_day.getDate() ) {
                                calendarOutput( year, month, today, true );
                                today++;
                            } else {
                            }
                        }
                    }
                    
                    str += '</tr>';
                }
                
                $tbody.append(str);
            };
        
            const calendarOutput = ( year, month, date, disable ) => {
                month = month < 10 ? `0${month}` : month;
                var new_date = date < 10 ? '0' + date : date;
        
                if( !disable ) {
                    console.log(111)
                    str += `<td class="cal__day cal__disabled">`;
                    str += '&nbsp;';
                } else {
                    str += `<td class="cal__day">`;
                    str += `<input type="checkbox" name="delivery_day" id="day_${year}${month}${new_date}" value="${year}${month}${new_date}">`;
                    str += `<label for="day_${year}${month}${new_date}">${date}</label>`;
                }
                
                str += `</td>`;
        
            };
        
            const calendarInit = () => {
                calendarBuild();
            };
        
            calendarInit();
        };
        

    const eventCheckAttendance_init = () => {
        calendarJs();
    };

    eventCheckAttendance_init();

};

export default eventCheckAttendance;