/**
 * Created by forbiz on 2020-11-10.
 */
import Swiper from 'swiper/dist/js/swiper';
 
const searchResult = () => {
    const $document = $(document);

    const searchResultSlider = () => {
        const $target = $('.searchResult-slide');
        const $slider = new Swiper($target, {
            spaceBetween: 16,
            slidesPerView: 2,
            slidesPerGroup: 2,
            pagination: {
                el: '.searchResult-slide .swiper-pagination',
                clickable: true,
            }
        });
    };

    const searchResult_init = () => {
        searchResultSlider();
    };

    searchResult_init();
}

export default searchResult;