/**
 * Created by Green on 2019-07-04.
 */


const planMealsDetail_write = () => {

    const $window = $(window);
    const $document = $(document);

    const form_event = () => {
        const $btn_sumbit = $('#btnSubmit');
        const $btn_cancel = $('#btnCancel');

        $btn_sumbit
            .on('click', function(e) {

                validation_event();

                return false;
            });
    };

    const validation_event = () => {
        const $question_form = $('.write-form');        

        if ( $question_form.find('#sendChoice').val() == '' ) {
            alert('문의 유형을 선택하세요.');
            return false;
        } else if ( $question_form.find('#sendTitle').val() == 0 ) {
            alert('문의 제목을 입력하세요.');
            return false;
        } else if( $question_form.find('#sendCont').val() == 0 ) {
            alert('문의 내용을 입력하세요.');
            return false;
        } else if( $question_form.find('#reciveID').val() == 0 ) {
            alert('메일 아이디를 입력하세요.');
            return false;
        } else if( $question_form.find('#reciveDomain').val() == 0 ) {
            alert('메일 를 입력하세요.');
            return false;
        } else {
            // location.href = 'http://localhost:827/front_mo/html/planMeals_detail.html';
        }
    };

    const secretCheck = () => {
        const $secret_mode = $('#secretMode');

        $secret_mode
            .on('change', function() {
                const $this = $(this);
                const this_check = $this.data('check');

                if ( $this.prop('checked') == true ) $this.attr('data-check', 'y');
                else $this.attr('data-check', 'n');
            });
    };


    const planMealsDetail_write_init = () => {
        form_event();
        secretCheck();
    };

    planMealsDetail_write_init();
};

export default planMealsDetail_write;