/**
 * Created by Green on 2019-06-27.
 */ 

import Swiper from 'swiper/dist/js/swiper'

const amore = () => {  
    const $window = $(window);
    const $document = $(document);
    const $body = $('body');

    const amoreSchedule = () => {
        const total_meals = () => {
            const $week = $('input[name="week"]');
            const $dining = $('input[name="dining"]');
            const $total = $('.final-count__num.count');
            const $btn_next = $('.btn__default.next');
            const $experienceYN = $('input[name="experienceYN"]');
            let week_val = 0;
            let dining_val = 0;
            let total = 0;
            let experienceYN_val = $experienceYN.val();

            $week
                .on('change', function() {
                    const $this = $(this);
                    const _val = $this.val();
                    week_val = parseInt(_val, 10);

                    if ( $this.attr('id') == 'experience' ) {
                        $('#weekend').hide().next().show();
                        $experienceYN.val("Y");
                    } else {
                        $('#exp').hide().prev().show();
                        $experienceYN.val("N");
                    }

                    input_check( $this, week_val );
                });

            $dining
                .on('change', function() {
                    const $this = $(this);
                    const _val = $this.val();
                    dining_val = parseInt(_val, 10);

                    input_check( $this, dining_val );
                });
            
            const input_check = ( $target, _data ) => {
                if ( $target.attr('name') == 'week' ) {
                    week_val = _data;
                    dining_val = 0;
                    $dining.prop('checked', false);
                    experienceYN_val = $experienceYN.val();                    

                    if (experienceYN_val == "Y") {
                        dining_val = 3;
                    }
                } else if ( $target.attr('name') == 'dining' ) {
                    dining_val = _data;
                }

                if ( week_val != 0 && dining_val != 0 ) {
                    total = week_val * dining_val;
                    $btn_next.addClass('active');
                    $total.text(total).attr('data-total-count', total);
                    $btn_next.find('.count-text').text('다음단계');
                } else {
                    $btn_next.removeClass('active');
                    $total.text(0).attr('data-total-count', 0);
                    $btn_next.find('.count-text').text('선택을 완료해주세요');
                }
            };
        };

        const amoreScheduleInit = () => {
            total_meals();
        };

        amoreScheduleInit();
    };

    const amoreChoice = () => {
        const $detail_layer = $('.popup__planMeals-detail');

        const amoreListDetail = () => {
            const $close = $detail_layer.find('.close-area a');
            let fisrt_st = 0;

            $document
                .on('click', '.detail-open', function(e) {
                    fisrt_st = $window.scrollTop();

                    $('body').css('paddingBottom', 0);
                    $('.amore__header').hide();
                    $('.hd__mchoice').hide();
                    $detail_layer.show();
                    $window.scrollTop(0);

                    e.preventDefault();
                });

            $close
                .on('click', function(e) {
                    $('body').css('paddingBottom', '85px');
                    $('.amore__header').show();
                    $('.hd__mchoice').show();
                    $detail_layer.hide();
                    
                    setTimeout(function() {
                        $window.scrollTop(fisrt_st);
                    }, 0);

                    e.preventDefault();
                });
        };

        const amoreListCount = () => {
            $document
                .on('click', '.mchoice__list .btn__count-down', function() {
                    const $this = $(this);
                    const $target = $this.siblings('input');
                    const _max_count = Number($target.attr('data-max-count'));

                    menuAmount( $this, $target, _max_count );
                })
                .on('click', '.mchoice__list .btn__count-up', function() {
                    const $this = $(this);
                    const $target = $this.siblings('input');
                    const _max_count = Number($target.attr('data-max-count'));
        
                    menuAmount( $this, $target, _max_count );
                })
                .on('keyup', '.mchoice__list__count input', function( e ) {
                    const $this = $(this);
                    const _val = $this.val();
                    const _max_count = Number($this.attr('data-max-count'));

                    if ( _val === '0' ) {
                        $this.siblings('.btn__count-down').attr('disabled', true);
                        $this.removeClass('on').closest('.mchoice__list__count').siblings('input[type="checkbox"]').prop('checked', false);
                    } else if ( _val > _max_count ) {
                        alert('최대수량입니다.');
                        return $this.val(_max_count);
                    }
                });

                const menuAmount = ( $button, $input, _maxCount ) => {
                    let _val = Number($input.val());

                    if ( $button.hasClass('btn__count-down') ) {
                        if ( 1 < _val ) {
                            _val -= 1;
                        } else {
                            $button.attr('disabled', true);
                            $input.removeClass('on').closest('.mchoice__list__count').siblings('input[type="checkbox"]').prop('checked', false);
                            _val = 0;
                        }
                    } else {
                        if ( _val < _maxCount ) {
                            $button.siblings('.btn__count-down').attr('disabled', false);
                            $input.addClass('on').closest('.mchoice__list__count').siblings('input[type="checkbox"]').prop('checked', true);
                            _val += 1;
                        } else {
                            alert('최대수량입니다.');
                            _val = _maxCount;
                        }
                    }

                    $input.val(_val);
                };
        };

        const amoreGift = () => {
            const $gift = $('.gift-Choice__list');

            const $giftSlide = new Swiper($gift, {
                spaceBetween: 28,
                slidesPerView: 2,
                slidesPerGroup: 2,
                pagination: {
                    el: '.gift-Choice__list .swiper-pagination',
                    clickable: false,
                }
            });
        };

        const amoreChoiceInit = () => {
            amoreListDetail();
            amoreListCount();
            amoreGift();
        };

        amoreChoiceInit();
    };

    const amoreDocbar = () => {
        let st_prev = 0;

        $window.on('scroll', function () {
            const _st = $window.scrollTop();
            const $target = $('.hd__btn-go-top');
            if( $('.amore__nav').length == 0 || !$body.hasClass('hd--fixed') ) {
                if( st_prev < _st && _st > 0 ) {
                    $body.addClass('docbar--hide');
                }  else {
                    $body.removeClass('docbar--hide');
                }
            }
            st_prev = _st;
        });
	};
	
    // lows_confirm.js:11
	// const amoreGiftChoice = () => {
	// 	$('.present__list').on('click' , function(){
 //            if(!$(this).hasClass('soldout')){
 //                if($(this).hasClass('on')){
 //                    $(this).removeClass('on');
 //                } else{
 //                    $(this).siblings('li').removeClass('on');
 //                    $(this).addClass('on');
 //                }
 //            }
	// 	})
	// }

    const amoreInit = () => {
        amoreSchedule();
        amoreChoice();
		amoreDocbar();
		//amoreGiftChoice();
    };

	amoreInit();
};

export default amore;