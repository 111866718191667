/**
 * Created by forbiz on 2019-02-11.
 */

import common from './divide/common';
import layout from './divide/layout';
import reg from './divide/reg.js';
import main from './divide/main';
import lows_schd from './divide/lows_schd';
import lows_mchoice from './divide/lows_mchoice';
import lows_confirm from './divide/lows_confirm';
import login from './divide/login';
import planMealsDetail from './divide/planMealsDetail';
import planMealsDetail_write from './divide/planMealsDetail_write';
import market from './divide/market.js';
import marketDetail from './divide/marketDetail.js';
import marketDesignation from './divide/marketDesignation.js';
import caulseAgree from './divide/caulseAgree.js';
import search from './divide/search.js';
import searchResult from './divide/searchResult.js';
import bestList from './divide/bestList.js';
import displayList from './divide/displayList.js';
import greatingLifeDetail from './divide/greatingLifeDetail.js';
import greatingLifeList from './divide/greatingLifeList.js';
import greatingTableDetail from './divide/greatingTableDetail.js';
import eventList from './divide/eventList.js';
import winnerList from './divide/winnerList.js';
import eventCheckAttendance from './divide/eventCheckAttendance.js';
import eventRoulette from './divide/eventRoulette.js';
import businessJoin from './divide/businessJoin.js';
import eventChallenge from './divide/eventChallenge.js';
import healthCare from './divide/healthCare.js';
import myOrderList from './divide/myOrderList.js';
import myOrderDetail from './divide/myOrderDetail.js';
import applyOrderExchange from './divide/applyOrderExchange.js';
import mypage from './divide/mypage.js';
import myPageMain from './divide/myPageMain.js';
import myReview from './divide/myReview.js';
import ecoDelivery from './divide/ecoDelivery.js';
import amore from './divide/amore.js';
import planMeals from './divide/planMeals.js';
import story from './divide/story.js';
import brandStory from './divide/brandStory.js';
// 마이페이지 > 결제영수증 이미지로 저장
import  html2canvas from 'html2canvas';

window.$ = window.jquery = window.jQuery = require('jquery');
window.html2canvas = html2canvas;


const appInit = () => {
    const appName = $('body').attr('id');
    if(appName) [common, layout, reg, appMethods[appName]].forEach(method  => {
        if(method) method();

    });
};

document.addEventListener('DOMContentLoaded', () => {
    appInit();
});


// rem 적용 페이지
if($('html').hasClass('wdw-type')) {
    /* resize */
    var htmlDoc = document.documentElement,
        enSizing = false;

    function setFontSize() {
        if( window.innerWidth > window.innerHeight ) return;
        htmlDoc.style.fontSize =  (parseInt((htmlDoc.offsetWidth/320*62.5) * 100000) / 100000) + '%';
    }
    window.onresize = function() {
        if (!enSizing) {
            window.requestAnimationFrame(function() {
                setFontSize();
                enSizing = false;
            });
        }
        enSizing = true;
    }
    window.dispatchEvent(new Event('resize'));
}


const appMethods = {
    layout,
    main,
    lows_schd,
    lows_mchoice,
    lows_confirm,
    login,
    planMealsDetail,
    planMealsDetail_write,
    market,
    marketDetail,
    marketDesignation,
    caulseAgree,
    search,
    searchResult,
    bestList,
    displayList,
    greatingLifeDetail,
    greatingLifeList,
    greatingTableDetail,
    eventList,
    winnerList,
    eventCheckAttendance,
    eventRoulette,
    businessJoin,
    eventChallenge,
    healthCare,
    myOrderList,
    myOrderDetail,
    applyOrderExchange,
    mypage,
    myPageMain,
    myReview,
    ecoDelivery,
    amore,
    planMeals,
    story,
    brandStory
};