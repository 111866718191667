/**
 * Created by Green on 2021-06-09.
 */

import Swiper from 'swiper/dist/js/swiper'

const greatingLifeList = () => {

    const lifeSwiper = () => {
        const $target = $('.greatingLife-list__swiper .swiper-container');
        const $slider = new Swiper($target, {
            direction: "vertical",
            slidesPerView: 'auto',
            touchReleaseOnEdges: true,
            loop: true,
            //mousewheel: true,
            //autoHeight : true,
            effect: "fade",
            //calculateHeight: true,
            //spaceBetween: 2,
            //slidesPerView: 2,
            // pagination: {
            //     el: '.relation-product .swiper-pagination',
            //     clickable: true,
            // }
        });

        // // if ( $('.swiper-slide').length < 1 ) {
        // //     $('.swiper-pagination').hide();
        // // }


    };

    // const greatingLifeListInit = () => {
    // };
    // greatingLifeListInit();

    window.lifeSwiper = lifeSwiper;
};

export default greatingLifeList;