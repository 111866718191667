/**
 * Created by Green on 2019-07-04.
 */

import Swiper from 'swiper/dist/js/swiper';

const front_common = () => {
	const $window = $(window);
	const $document = $(document);
	const $htmlbody = $('html, body');
	const $body = $('body');
	const $header = $('.hd__header');

	// 정기식단 마켓, 지정일배송메뉴 상세보기 Script
	const commDetailJs = () => {
		const $detail_wrap = $('.detail-wrap');
		const $tnb_area = $('.tnb-area');
		const $tnb_list = $('.tnb-area__list');
		let $target;

		// Scroll Event
		const commDetailScroll = () => {
			const $close_area = $('.close-area');
			const $overview = $('.overview');

			// $window.on('scroll', function () {
			// 	const _st = $window.scrollTop();

			// 	if (_st > $close_area.height() + $overview.height()) {
			// 		// $close_area.addClass('close-area--hidden').css({
			// 		//     'top' : $close_area.height() + $overview.height()/* - 11*/     //11은 닫기버튼에 after
			// 		// });
			// 	} else {
			// 		// $close_area.removeClass('close-area--hidden').css({
			// 		//     'top' : 0
			// 		// });
			// 	}

			// 	if ($detail_wrap.length > 0) {
			// 		if (_st > $detail_wrap.offset().top - $close_area.height()) {
			// 			$tnb_area.addClass('tnb-area--fixed');
			// 		} else {
			// 			$tnb_area.removeClass('tnb-area--fixed');
			// 		}
			// 	}
			// });

			$document.on('click', '.hd__btn-go-top', function () {
				$htmlbody.stop().animate({
					scrollTop: 0,
				});

				return false;
			});
		};

		// 상세 대메뉴 탭 Event
		const commDetailTnb = () => {
			$tnb_list.on('click', function () {
				const $this = $(this);
				const tab_name = $this.data('tab');
				$target = $('#' + tab_name);

				if ($target.find('.board__list').length > 0 || $target.find('.tab__style').length > 0 || $target.find('.notice-list').length > 0) {
					$target.find('.board__list').removeClass('on').find('.answer').hide();
					$target.find('.review__cont').hide();
					$target.find('.tab__style').find('li').removeClass('on').eq(0).addClass('on');
					$target.find('.notice-list').removeClass('notice-list--show').find('.notice-list__cont').hide();
				}

				$this.addClass('tnb-area__list--active').siblings().removeClass('tnb-area__list--active');
				$target.addClass('tab-contents--current').siblings().removeClass('tab-contents--current');

				return false;
			});
		};

		// 상품후기/문의, 배송/교환 아코디언 Event
		const commDetailAcco = () => {
			$document
				.on('click', '.board__inner__review.question', function () {
					const $this = $(this);
					if ($this.find('.review__cont').length <= 1) {
						$this.find('.review__cont').stop().slideToggle();
					}

					$this.next().stop().slideToggle();
					$this.closest('.board__list').toggleClass('on');
				})
				.on('click', '.notice-list__tit', function () {
					const $this = $(this);

					$this.next().stop().slideToggle();
					$this.closest('.notice-list').toggleClass('notice-list--show');
				});
		};

		// 상품문의 탭 Event
		const commDetailQA = () => {
			const $qa_tab = $('.tab__style');
			const $qa_list = $qa_tab.find('li');

			$qa_list.on('click', function () {
				const $this = $(this);

				$this.addClass('on').siblings().removeClass('on');

				return false;
			});
		};

		const commDetailInit = () => {
			commDetailScroll();
			commDetailTnb();
			commDetailAcco();
			commDetailQA();
		};

		commDetailInit();
	};
	const commDetailJs2 = () => {
        $document.on('click', '.item-box .list-more-btn', function() {
            const $target = $(this).closest('.item-box__list');

            if ($target.hasClass('active')){
                 $target.removeClass('active');
                 $(this).next('.list-more').slideUp();
            } else{
                $target.addClass('active');
                $(this).next('.list-more').slideDown();
            };
        });
    };

	/*
    - 레이어팝업 Event
        * 레이어팝업 Open
            버튼 Class - btn__modal-open / data-popup-name=".팝업 클래스명" / 레이어팝업에 동일 팝업 클래스명 추가
        * 레이어팝업 Close : 버튼 Class - btn__modal-close
    */

	const modalPopUp = {
		before_st: 0,
		popupOpen($target) {
			const $popup = $($target.data('popup-name'));
			this.before_st = $window.scrollTop();

			$body.addClass('hd--fixed').css({
				top: -this.before_st,
				height: $window.outerHeight() + this.before_st - parseInt($body.css('paddingBottom'), 10),
				overflow: 'hidden',
			});

			$popup.addClass('popup__modal-open').css({
				height: $window.outerHeight(),
				marginTop: this.before_st,
			});

			$header.css({
				marginTop: this.before_st,
			});
		},
		popupClose($target) {
			const $popup = $target.closest('.hd__popup');

			$body.removeClass('hd--fixed').css({
				top: '',
				height: '',
				overflow: '',
			});

			$popup.removeClass('popup__modal-open').css({
				height: '',
				marginTop: '',
			});

			$header.css({
				marginTop: '',
			});

			$window.scrollTop(this.before_st);
			
			$('#alert-layer').css('top', '');		//fnAlert top 제거
			$('#confirm-layer').css('top', '');		//fnConfirm top 제거
		},
	};

	const modalEvent = () => {
		const $btn_close = $('.btn__modal-close');

		$document.on('click', '.btn__modal-open', function (e) {
			const $this = $(this);
			const loginCheck = $this.data('login');

			if (loginCheck == 'n') modalPopUp.popupOpen($this);
			else return false;

			e.preventDefault();
		});

		$btn_close.on('click', function (e) {
			const $this = $(this);
			const $target = $this.closest('.hd__popup');

			modalPopUp.popupClose($this);

			// 그리팅페이 등록,삭제.결제 재진입시 입력값 초기화
			if (
				$target.hasClass('popup_greatingPayCard') ||
				$target.hasClass('popup_greatingPayPwConfirm') ||
				$target.hasClass('popup_greatingPayPwDelete') ||
				$target.hasClass('popup_greatingPayPw')
			) {
				$target.find('input').val('');
			}
			
			e.preventDefault();
		});
	};

	/*
    - 미니카트 팝업 이벤트(추후 버튼에 대한 로그인 처리 해야되는지 여부 결정)
    */
	const miniCart = () => {
		const $cartBtn = $('.btn__cart');
		const $layer = $('.options__inner.options');
		const $layer_bg = $('.options__modal');

		const miniCartOpen = () => {
			$cartBtn.on('click', function () {
				const $this = $(this);

				if (!$layer.hasClass('open')) {
					$layer.css('transform', 'translate3d(0, -' + $cartBtn.height() + 'px, 0)').addClass('open');
					$layer_bg.addClass('modal-open');
					$('body').addClass('cartHidden').on('scroll touchmove mousewheel', function(e){e.preventDefault();});
				}
				// } else {
				//     if ( $this.data('url') == 'cart' ) {
				//         console.log('장바구니');
				//     } else {
				//         console.log('바로주문');
				//     }
				// }

				return false;
			});
		};

		const miniCartClose = () => {
			$document.on('click.close', '.options__inner.options .btn__close__cart', function (e) {
				closeCart();

				e.preventDefault();
			});

			$layer_bg.on('click', function (e) {
				closeCart();

				e.preventDefault();
			});

			// 미니카트 close
			const closeCart = () => {
				if ($layer.hasClass('open')) {
					$layer.css('transform', 'translate3d(0, ' + $layer.outerHeight(true) + 'px, 0)');
					$layer_bg.removeClass('modal-open');
					$layer.removeClass('open');
					$('.hd__btn-sec2').hide();
					$('body').removeClass('cartHidden').off('scroll touchmove mousewheel');
				}
			};
		};

		const deleteCartList = () => {
			$document.on('click', '.options__inner.options .list__delete', function () {
				const $this = $(this);
				const $list = $this.closest('li');

				$list.addClass('delete');

				setTimeout(function () {
					$list.remove();
				}, 800);

				return false;
			});
		};

		const miniCartInit = () => {
			miniCartOpen();
			miniCartClose();
			deleteCartList();
		};

		miniCartInit();
	};

	/* 주문결제-합배송선택 팝업이벤트 */
	const comDelivery = () => {
		const $choice = $('.comDelivery input:checkbox[name=check]');
		const $noChoice = $('.comDelivery input:checkbox[name=no-choice]');
		const $nextBtn = $('.btn__default');

		$noChoice.on('click', function () {
			const $this = $(this);
			if ($this.prop('checked') == true) {
				$choice.prop('checked', false);
				$nextBtn.addClass('active');
			} else {
				$nextBtn.removeClass('active');
			}
		});

		$choice.on('click', function () {
			const $this = $(this);
			if ($this.prop('checked') == true) {
				$noChoice.prop('checked', false);
				$nextBtn.addClass('active');
			} else {
				$nextBtn.removeClass('active');
			}
		});
	};

	// 마켓 서브메뉴 공통 이벤트
	const market_menu = () => {
		// const market_slideCate = {
		// 	depth1() {
		// 		const $depth1 = $('.hd__market .category__depth1');
		// 		const $depth1_idx = $depth1.find('.active').index();
		// 		const swiperDepth1 = new Swiper('.hd__market .category__depth1', {
	 //                slidesPerView: 'auto',
	 //                freeMode: true
	 //            });

	 //            $(window).on('load', function(e){
	 //                swiperDepth1.slideTo($depth1_idx-1, 300);
	 //                e.preventDefault();
	 //            });
		// 	},

		// 	depth2() {
		// 		const $depth2 = $('.hd__market .category__depth2');
		// 		const $depth2_idx = $depth2.find('.active').index();

	   			

	 //            const swiperDepth2 = new Swiper('.hd__market .category__depth2', {
	 //                slidesPerView: 'auto',
	 //                freeMode: true
	 //            });

	 //            $(window).on('load', function(e){
	 //                swiperDepth2.slideTo($depth1_idx-1, 300);
	 //                e.preventDefault();
	 //            });
		// 	}
		// };


		const cateOpen = () => {
			$document.on('click', '.cate-btn', function () {
				const $this = $(this);
				const $cateOpen = $('.cate-open');

				$cateOpen.toggleClass('cate-open--show');
				$this.toggleClass('cate-btn--on');

				return false;
			});
		};
		cateOpen();
	};

	const market_sorting = () => {
		$document
			.on('click', '.sorting', function () {
				const $this = $(this);
				const $target = $(this).find('.select-area');
				$target.toggleClass('select-area--show');
			})

			.on('click', '.select-area__list', function () {
				const $this = $(this);
				$this.addClass('select-area__list--on').siblings().removeClass('select-area__list--on');
			});
	};

	// 펼쳐보기 slide up-down 이벤트
	const orderCart = () => {
		$document.on('click', '.btn--listMore', function (e) {
			const $this = $(this);

			if ($this.hasClass('on')) {
				$this.removeClass('on').find('span').text('펼쳐보기');
				$this.next('.product--plan').stop().slideUp();
			} else {
				$this.addClass('on').find('span').text('접어두기');
				$this.next('.product--plan').stop().slideDown();
			}

			e.preventDefault();
		});
	};

	const orderListAccd = () => {
		$document.on('click', '.btn__mealsMore', function (e) {
			const $this = $(this);

			if ($this.hasClass('on')) {
				$this.removeClass('on').find('span').text('펼쳐보기');
				$this.next('.regular-meals').stop().slideUp();
			} else {
				$this.addClass('on').find('span').text('접어두기');
				$this.next('.regular-meals').stop().slideDown();
			}

			e.preventDefault();
		});
	};

	// 아코디언 이벤트
	const accordion = () => {
		$document.on('click', '.accordion_box .accordion_title', function (e) {
			const $this = $(this);
			const $accodionBox = $this.closest('.accordion_box');
			const $target = $this.siblings('.accordion_con');

			if ($accodionBox.hasClass('on')) {
				$accodionBox.removeClass('on');
				$target.stop(true, true).slideUp();
			} else {
				$accodionBox.addClass('on');
				$target.stop(true, true).slideDown();
			}
			e.preventDefault();
		});
	};

	// 주문결제 배송지정보 영역 이벤트
	const deliveryInfo = () => {
		// 배송지선택/신규배송지 tab 이벤트
		const tabEvent = () => {
			$document.on('click', '.tab-list-v2', function () {
				const $this = $(this);
				const tab_name = $this.data('tab');
				const $target = $('#' + tab_name);

				$this.addClass('tab-list-v2--on').siblings().removeClass('tab-list-v2--on');
				$target.addClass('current').siblings().removeClass('current');

				return false;
			});
		};

		// 배송요청사항 이벤트
		const directWrite = () => {
			$document.on('change', function () {
				const val = $('#demandYn option:selected').val();
				const $textArea = $('.textarea_box');

				if ($('#demandYn').length >= 1) {
					if (val == '1') {
						$textArea.show();
					} else {
						$textArea.hide();
					}
				}
			});
		};

		// 글자수 제한
		const limit_text = () => {
			$document.on('keyup', '.textarea_box textarea', function () {
				const $this = $(this);
				const $text_count = $('.text_count span');
				if ($this.val().length > 30) {
					$this.val($this.val().substring(0, 30));
					$text_count.text(30);
				} else {
					$text_count.text($this.val().length);
				}
			});
		};

		// 새벽배송 정보입력 radio check 이벤트
		const dawnDelivery = () => {
			const $infoBox = $('.dawnDelivery-info .infobox');
			const $home = $infoBox.find('input[name=home]');
			const $seOffice = $infoBox.find('input[name=seOffice]');
			const $visitWay = $infoBox.children('.visit-way');

			$home.on('change', function () {
				const $this = $(this);
				if ($this.prop('checked') == true) {
					$visitWay.addClass('show');
					$seOffice.prop('checked', false);
				} else {
				}
			});

			$seOffice.on('change', function () {
				const $this = $(this);
				if ($this.prop('checked') == true) {
					$visitWay.removeClass('show');
					$home.prop('checked', false);
				} else {
				}
			});

			$visitWay.on('change', function () {
				const $checkArea = $visitWay.find('input[name=check]');
				const $frontPW = $visitWay.find('input[id=visitWay1]');
				const $inputArea = $visitWay.find('input[name=frontPWInput]');

				if ($frontPW.prop('checked') == true) {
					$inputArea.show();
					$checkArea.prop('checked', false);
					$frontPW.prop('checked', true);
				} else {
					$inputArea.hide();
				}
			});
		};

		// 일반배송 체크 이벤트
		const normalDelivery = () => {
			$document.on('click', '.normal-delivery .checks', function () {
				const $this = $(this);
				const $checkInput = $this.children('input[name=deliveryNormal]');
				const $dawnDeliveryInfo = $('.dawnDelivery-info');
				const $dawnDeliveryDesc = $('.dawnDelivery--desc');
				const $dawnDeliveryDescType2 = $('.dawnDelivery--desc-type2');

				if ($checkInput.prop('checked') == true) {
					$this.addClass('on');
					$dawnDeliveryInfo.hide();
					$dawnDeliveryDesc.hide();
					$dawnDeliveryDescType2.show();
				} else {
					$this.removeClass('on');
					$dawnDeliveryInfo.show();
					$dawnDeliveryDesc.show();
					$dawnDeliveryDescType2.hide();
				}
			});
		};

		// 일반상품 배송지 - 따로 배송받기
		const seperate = () => {
			const $seperate = $('.seperate');
			const $checkArea = $seperate.find('.seperate__checks');

			$checkArea.on('click', function () {
				const $this = $(this);
				const $checkInput = $this.children('input[name=deliSeperate]');
				const $target = $('.seperate__cont');

				if ($checkInput.prop('checked') == true) {
					$this.addClass('on');
					$target.show();
				} else {
					$this.removeClass('on');
					$target.hide();
				}
			});

			$document.on('click', '.seperate .tab-list-v2', function () {
				const $this = $(this);
				const tab_name = $this.data('tab');
				const $cont = $('#' + tab_name);

				$this.addClass('tab-list-v2--on').siblings().removeClass('tab-list-v2--on');
				$cont.addClass('current').siblings().removeClass('current');

				return false;
			});
		};

		tabEvent();
		directWrite();
		limit_text();
		dawnDelivery();
		normalDelivery();
		seperate();
	};

	// 주문결제 결제수단선택 클릭이벤트
	const paymentMethod = () => {
		const payType = () => {
			$document.on('click', '.pay-type ul li', function () {
				const $this = $(this);

				$this.addClass('on').siblings().removeClass('on');
			});
		};

		const assoArea = () => {
			$document.on('click', '.assoPayBtn', function () {
				const $this = $(this);
				const $target = $this.siblings('.certi-area');

				$target.show();
			});
		};

		payType();
		assoArea();
	};

	// 상품검색 이벤트
	const search = () => {
		const categoryPick = () => {
			$document.on('click', '.cate-area__list', function () {
				const $this = $(this);
				const _categoryVal = $this.closest('input[type=checkbox]');

				_categoryVal.prop('checked', true);
			});
		};

		const categoryReset = () => {
			$document.on('click', '.cate-reset-btn', function () {
				const $categoryList = $('.cate-area__list input[type=checkbox]');

				$categoryList.prop('checked', false);
			});
		};

		categoryPick();
		categoryReset();
	};

	// 상품정보 더보기
	const jsDetailBtn = () => {
		$document.on('click','.js-toggle-button', function(){
	        var text = $(this).find('span').text();
        	$(this).toggleClass('on');
        	$('.js-toggle-content').toggleClass('on');

	        if ($(this).hasClass('on')) {
	        	$(this).find('span').text('접기');
	        } else{
	        	$(this).find('span').text('더보기');
	        }


			// var areaCode = $(this).data('montelenaAcode');
	  //       if (text == '더보기') {
	  //           pdsClickLog(areaCode, 'utility', {});
	  //       }
		});
	};

	// 공통 슬라이드
	let main_slider = '.px__slider';
	let interleaveOffset = 0.5;

	const market_slider_opts = {
		autoplay: false, // 자동재생
		grabCursor: true, // cursor 손모양으로 변경
		loop: false, // 반복
		loopAdditionalSlides: 10, // loop true일 경우 list 복제(왜 있는지 모르겠음)
		parallax: true,
		speed: 1000, // 슬라이드 진행 속도
		watchSlidesProgress: true, // 진행률을 계산

		pagination: {
			el: '.overview .swiper-pagination',
			clickable: true,
		},

		on: {
			imagesReady: function () {
				this.el.classList.remove('loading');
			},
			progress: function () {
				let swiper = this;

				for (let i = 0; i < swiper.slides.length; i++) {
					let slideProgress = swiper.slides[i].progress,
						innerOffset = swiper.width * interleaveOffset,
						innerTranslate = slideProgress * innerOffset;

					$(swiper.slides[i])
						.find('.px__slider__bgimg')
						.css('transform', 'translate3d(' + innerTranslate + 'px, 0, 0)');
				}
			},
			touchStart: function () {
				let swiper = this;

				for (let i = 0; i < swiper.slides.length; i++) {
					swiper.slides[i].style.transition = '';
				}
			},
			setTransition: function (speed) {
				let swiper = this;

				for (let i = 0; i < swiper.slides.length; i++) {
					$(swiper.slides[i]).css('transition', 'all ' + speed + 'ms');
					$(swiper.slides[i])
						.find('.px__slider__bgimg')
						.css('transition', 'all ' + speed + 'ms');
				}
			},
		},
	};

	const overview_slider = () => {
		const $target = $('.overview__thumb');
		const $slider = new Swiper($target, market_slider_opts);
	};

	// 마이페이지 > 결제영수증 이미지로 저장
	const receipt_screen = () => {
		function onScreenShotClick(e) {
			const $capture = $('#receiptView');
			$window.scrollTop(0);

			html2canvas($capture[0]).then((canvas) => {
				const myImage = canvas.toDataURL();
				const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

				if (navigator.userAgent.match(/GreatingAOSApp/)) {
					// android app
					window.JavascriptInterface.saveImage(myImage);
				} else {
					if (iOS == true) {
						// ios web app
						e.target.setAttribute('target', '_blank');
					}

					// android web
					$window.scrollTop($capture.height());
					downloadURI(myImage, 'receipt.jpg');
				}
			});
		}

		function downloadURI(uri, name) {
			var link = document.createElement('a');
			link.download = name;
			link.href = uri;
			document.body.appendChild(link);
			link.click();
		}

		$document.on('click', '.saveImg', function (e) {
			onScreenShotClick(e);
			return false;
		});
	};

	const fileLoad = () => {
		$document.on('change', '.input_file', function () {
			const $this = $(this);
			const _memory = Number($this.data('maxmemory-mb'));
			const _maxSize = _memory * 1024 * 1024; // MB 단위, KB일 경우 1024 한 개 제거
			const files = $this[0].files;
			let _thisSize = 0;

			if (files && files[0]) {
				if (files[0].type.match(/(jpg|jpeg|png)/i) != null) {
					let reader = new FileReader();

					_thisSize = files[0].size;

					if (_thisSize >= _maxSize) {
						alert(`${_memory}MB 미만의 파일만 등록 가능합니다.`);
						errorFn($this);
					} else {
						$this.siblings('.del').show();

						reader.onload = function (e) {
							$this.siblings('img').attr('src', e.target.result);
						};
					}

					reader.readAsDataURL(files[0]);
				} else {
					alert('jpg 또는 png 파일만 등록 가능합니다.');
					errorFn($this);
				}
			} else {
				errorFn($this);
			}
		});

		function errorFn($target) {
			$target.siblings('.del').hide();
			$target.val('').siblings('img').attr('src', '/front_mo/images/img_comm_preview.png');
		}

		$document.on('click', '.btn_icon', function () {
			var $this = $(this);

			$this.hide();
			$this.siblings('img').attr('src', '/front_mo/images/img_comm_preview.png').prev('input[type="file"]').val('');
		});
	};

	const storageSet = () => {
		$document.on('click', '.newPage', function () {
			localStorage.setItem('newPage', 'y');
		});
	};

	const progressBar = () => {
        var $bar = $(".progress_wrap .progress_bar");
        var per = $bar.attr('data-value');
        var pre = $bar.attr('data-pre');
        var i = 0;

        if(pre != undefined){
            i = pre;
        }

        var intervalId = setInterval(function(){
            if(i < per){
               i++;
                $bar.css('width',i+'%');  
            } else if(i > per){
                i--;
                $bar.css('width',i+'%');
            } else{
               clearInterval(intervalId);   
               if(i == 100){
                    setTimeout(function() {
                        $(".progress_wrap").addClass('active');
                    },150);  
		       } else if(i < 100){
		              setTimeout(function() {
                        $(".progress_wrap").removeClass('active');
                    },150);  
		       }
            }    
        },10);

        $bar.attr('data-pre',per);
    };

    const jsTab = () => {
    	if( $('.js-tab').length > 0){
	    	$document
		        .on('click', ".js-tab li", function() {
		            const $this = $(this);
		            const $tnb_area = $this.closest('.tab-list');
		            
		            const tab_name = $this.data('js-tab');
		            const $target = $('#' + tab_name);

		            $this.addClass('tab-list--on').siblings().removeClass('tab-list--on');
		            $target.addClass('tab-contents--current').siblings().removeClass('tab-contents--current');

		            return false;
		        });
    	}
    };

	const front_common_init = () => {
		commDetailJs();
		commDetailJs2();
		modalEvent();
		miniCart();
		comDelivery();
		market_menu();
		market_sorting();
		orderCart();
		accordion();
		deliveryInfo();
		paymentMethod();
		search();
		receipt_screen();
		fileLoad();
		storageSet();
		orderListAccd();
		jsTab();
		jsDetailBtn();
	};

	front_common_init();

	const cardSlide = {
		slide: '',
		cardSlideStart() {
			this.slide = new Swiper('.greating-pay__sec.card .card__wrap', {
				centeredSlides: true,
				pagination: {
					el: '.greating-pay__sec.card .swiper-pagination',
					clickable: false,
				},
				slidesPerView: 1,
			});
		},
		cardSlideEnd() {
			this.slide.destroy();
			this.slide = '';
		},
	};

	const todaySlide = {
		today() {
			new Swiper('.today-popup .swiper-container', {
				autoplay: true,
				loop: true,
				pagination: {
					el: '.today-popup .swiper-pagination',
					clickable: false,
				},
			});
		},
	};

	window.progressBar = progressBar;
	window.modalPopUp = modalPopUp;
	window.overview_slider = overview_slider;
	window.cardSlide = cardSlide;
	window.todaySlide = todaySlide;
};

export default front_common;
