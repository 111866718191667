/**
 * Created by Green on 2019-07-04.
 */

import Swiper from 'swiper/dist/js/swiper';
const marketDetail = () => {
	const market_slider = () => {
		// Params
		let main_slider = '.px__slider';
		let interleaveOffset = 0.5;

		const market_slider_opts = {
			autoplay: false, // 자동재생
			grabCursor: true, // cursor 손모양으로 변경
			loop: false, // 반복
			loopAdditionalSlides: 10, // loop true일 경우 list 복제(왜 있는지 모르겠음)
			parallax: true,
			speed: 1000, // 슬라이드 진행 속도
			watchSlidesProgress: true, // 진행률을 계산

			//			pagination: {
			//				el: '.overview .swiper-pagination',
			//				clickable: true,
			//			},

			on: {
				init: updSwiperNumericPagination,
				slideChange: updSwiperNumericPagination,
				imagesReady: function () {
					this.el.classList.remove('loading');
				},
				// slideChangeTransitionEnd: function() {
				// let swiper = this;
				// },
				progress: function () {
					let swiper = this;

					for (let i = 0; i < swiper.slides.length; i++) {
						let slideProgress = swiper.slides[i].progress,
							innerOffset = swiper.width * interleaveOffset,
							innerTranslate = slideProgress * innerOffset;

						$(swiper.slides[i])
							.find('.px__slider__bgimg')
							.css('transform', 'translate3d(' + innerTranslate + 'px, 0, 0)');
					}
				},
				touchStart: function () {
					let swiper = this;

					for (let i = 0; i < swiper.slides.length; i++) {
						swiper.slides[i].style.transition = '';
					}
				},
				setTransition: function (speed) {
					let swiper = this;

					for (let i = 0; i < swiper.slides.length; i++) {
						$(swiper.slides[i]).css('transition', 'all ' + speed + 'ms');
						$(swiper.slides[i])
							.find('.px__slider__bgimg')
							.css('transition', 'all ' + speed + 'ms');
					}
				},
			},
		};

		function updSwiperNumericPagination() {
			this.el.querySelector('.swiper-counter').innerHTML =
				'<span class="count">' + (this.realIndex + 1) + '</span>/<span class="total">' + this.slides.length + '</span>';
		}
		const overview_slider = () => {
			const $target = $('.overview__thumb');
			const $slider = new Swiper($target, market_slider_opts);
		};

		const relGoods_slider = () => {
			const $target = $('.rel-goods');
			const $slider = new Swiper($target, {
				spaceBetween: 28,
				slidesPerView: 2,
				slidesPerGroup: 2,
				pagination: {
					el: '.rel-goods .swiper-pagination',
					clickable: true,
				},
			});
		};

		overview_slider();
		relGoods_slider();
	};

	const marketDetail_init = () => {
		market_slider();
	};

	marketDetail_init();
};

export default marketDetail;
