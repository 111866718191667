/**
 * Created by Green on 2019-06-27.
 */

const lows_schd = () => {
    const total_meals = () => {
        const $week = $('input[name="week"]');
        const $dining = $('input[name="dining"]');
        const $total = $('.final-count__num.count');
        const $btn_next = $('.btn__default.next');
        const $btn_quick = $('.btn__default.quick');
        const $btn_quick_tool_tip = $('.btn-tooltip');
        const $experienceYN = $('input[name="experienceYN"]');
        let week_val = 0;
        let dining_val = 0;
        let total = 0;
        let experienceYN_val = $experienceYN.val();

        $week
            .on('change', function() {
                const $this = $(this);
                const _val = $this.val();
                week_val = parseInt(_val, 10);

                if ( $this.attr('id') == 'experience' ) {
                    $('#weekend').hide().next().show();
                    $experienceYN.val("Y");
                } else {
                    $('#exp').hide().prev().show();
                    $experienceYN.val("N");
                }

                input_check( $this, week_val );
            });

        $dining
            .on('change', function() {
                const $this = $(this);
                const _val = $this.val();
                dining_val = parseInt(_val, 10);

                input_check( $this, dining_val );
            });
        
        const input_check = ( $target, _data ) => {
            if ( $target.attr('name') == 'week' ) {
                week_val = _data;
                dining_val = 0;
                $dining.prop('checked', false);
                experienceYN_val = $experienceYN.val();

                if (experienceYN_val == "Y") {
                    dining_val = 3;
                }
            } else if ( $target.attr('name') == 'dining' ) {
                dining_val = _data;
            }

            if ( week_val != 0 && dining_val != 0 ) {
                total = week_val * dining_val;
                $btn_next.addClass('active');
                $btn_quick.addClass('active');
                $btn_quick.addClass('orange');
                $btn_quick_tool_tip.css('display', '');
                $total.text(total).attr('data-total-count', total);
            } else {
                $btn_next.removeClass('active');
                $btn_quick.removeClass('active');
                $btn_quick.removeClass('orange');
                $btn_quick_tool_tip.css('display', 'none');
                $total.text(0).attr('data-total-count', 0);
            }
        };
    };

    const lows_schd_init = () => {
        total_meals();
    };

    lows_schd_init();
}

export default lows_schd;