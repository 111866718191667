/**
 * Created by Green on 2019-06-27.
 */

import Swiper from 'swiper/dist/js/swiper'

const planMeals = () => {  
    const $window = $(window);
    const $document = $(document);
    const $body = $('body');

    const planMeals_slider = {
        banner() {
            const $target = $('.hd__plan-meals .program_banner.swiper-container');
            const $length = $target.find('.swiper-slide').length;
                
            if ( $length > 1 ) {
                const $slider = new Swiper($target, {
                    loop: true,
                    speed: 1000,
                    touchReleaseOnEdges: true,
                    autoplay: {
                        delay: 3500,
                        disableOnInteraction: false,
                    },
                    on: {
                        init: updSwiperNumericPagination,
                        slideChange: updSwiperNumericPagination
                    }
                });

                function updSwiperNumericPagination() {
                    this.el.querySelector('.hd__plan-meals .program_banner .swiper-counter').innerHTML =
                        '<span class="count">' + (this.realIndex + 1) + '</span>/<span class="total">' + (this.slides.length-2) + '</span>';
                }
            } else{
                $target.find(".swiper-counter").hide(); 
            }
            
        },

        slider() {
            const $target = $('.hd__plan-meals .program_slider');

            const $slider = new Swiper($target, {
                loop: true,
                slidesPerView: 'auto',
                touchReleaseOnEdges: true,
                pagination: {
                  el: ".hd__plan-meals .program_slider .swiper-pagination",
                  type: "progressbar",
                },
                on: {
                    init: updSwiperNumericPagination,
                    slideChange: updSwiperNumericPagination
                }
            });

            function updSwiperNumericPagination() {
                var totalSlides = $('.hd__plan-meals .program_slider .swiper-slide:not(.swiper-slide-duplicate)').length;

                this.el.querySelector('.hd__plan-meals .program_slider .swiper-counter').innerHTML =
                    '<span class="count">' + (this.realIndex + 1) + '</span>/<span class="total">' + totalSlides + '</span>';
            }
        },

        swiper() {
            const $target = $('.hd__plan-meals .program_swiper');

            const $slider = new Swiper($target, {
                loop: true,
                speed: 1000,
                touchReleaseOnEdges: true,
                autoplay: {
                    delay: 3500,
                    disableOnInteraction: false,
                },
                pagination: {
                  el: ".hd__plan-meals .program_swiper .swiper-pagination",
                  type: "progressbar",
                },
                on: {
                    init: updSwiperNumericPagination,
                    slideChange: updSwiperNumericPagination
                }
            });

            function updSwiperNumericPagination() {
                var totalSlides = $('.hd__plan-meals .program_swiper .swiper-slide:not(.swiper-slide-duplicate)').length;

                this.el.querySelector('.hd__plan-meals .program_swiper .swiper-counter').innerHTML =
                    '<span class="count">' + (this.realIndex + 1) + '</span>/<span class="total">' + totalSlides + '</span>';
            }
        }
    };

    const planMealsInit = () => {
        
    };

	planMealsInit();

    window.planMeals_slider = planMeals_slider;
};

export default planMeals;