/**
 * Created by Green on 2019-07-04.
 */

import Swiper from 'swiper/dist/js/swiper';

const eventChallenge = () => {
	const eventChallenge_slider = () => {
		// Params
		let eventChallenge_slider = '.px__slider';
		let interleaveOffset = 0.5;

		const display_slider_opts = {
			autoplay: false, // 자동재생
			grabCursor: true, // cursor 손모양으로 변경
			loop: false, // 반복
			speed: 1000, // 슬라이드 진행 속도
			spaceBetween: 0,

			pagination: {
				el: '.hd__eventChallenge .swiper-pagination',
				clickable: false,
			},
			on: {
				slideChangeTransitionStart: function () {
					if (this.activeIndex === $bulletLen - 1) {
						$('.btn-area').css({ visibility: 'visible' });
					} else {
						$('.btn-area').css({ visibility: 'hidden' });
					}
				},
			},
		};

		const banner_slider = () => {
			const $target = $('.hd__eventChallenge .challenge-banner');
			const $slider = new Swiper($target, display_slider_opts);
			const $pagination = $('.hd__eventChallenge .swiper-pagination');

			if ($target.find('.swiper-slide').length == 1) {
				$pagination.hide();
			}
		};

		banner_slider();
		const $bulletLen = $('.hd__eventChallenge .swiper-pagination-bullet').length;
	};

	const eventChallenge_init = () => {
		// eventChallenge_slider();
	};

	eventChallenge_init();

	window.eventChallenge_slider = eventChallenge_slider;
};

export default eventChallenge;
