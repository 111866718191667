/**
 * Created by Green on 2019-07-04.
 */

import Swiper from 'swiper/dist/js/swiper';

const main = () => {
	const $window = $(window);
	const $document = $(document);
	const interleaveOffset = 0.5;
	const story_slider_opts = {
		autoplay: true, // 자동재생
		grabCursor: true, // cursor 손모양으로 변경
		loop: false, // 반복
		loopAdditionalSlides: 10, // loop true일 경우 list 복제(왜 있는지 모르겠음)
		autoplay: {
			delay: 3500,
			autoplayDisableOnInteraction: false,
		},
		autoplayDisableOnInteraction: false,
		pagination: {
			el: '.hd__main__story .swiper-pagination',
			clickable: false,
		},
		parallax: true,
		speed: 1000, // 슬라이드 진행 속도
		watchSlidesProgress: true, // 진행률을 계산
		spaceBetween: 20,
		width: 450,
		on: {
			init() {
				const swiper = this;

				for (let i = 0; i < swiper.slides.length; i++) {
					let slideProgress = swiper.slides[i].progress;
					let innerOffset = swiper.width * interleaveOffset;
					let innerTranslate = slideProgress * innerOffset;

					//  if( i == 0 ) {
					//      $(swiper.slides[i]).css({
					//          'transform' : 'translate3d(0, 0, 0)'
					//      });
					//  } else {
					//     $(swiper.slides[i]).find(".story-box__thumb figure").css({
					//         'transform': 'translate3d('+ innerTranslate +'px, 0, 0)'
					//     });
					//     $(swiper.slides[i]).css({
					//         'transform' : 'translate3d(-'+ (slideProgress * - 167) +'px, 0, 0)'
					//     });
					//  }
				}
				flag = false;
			},
			imagesReady() {
				const swiper = this;

				swiper.el.classList.remove('loading');
			},
			progress() {
				const swiper = this;

				if (!flag) {
					for (let i = 0; i < swiper.slides.length; i++) {
						let slideProgress = swiper.slides[i].progress;
						let innerOffset = swiper.width * interleaveOffset;
						let innerTranslate = slideProgress * innerOffset;

						// $(swiper.slides[i]).find(".story-box__thumb figure").css({
						//     'transform': 'translate3d('+ innerTranslate +'px, 0, 0)'
						// });

						// $(swiper.slides[i]).css({
						//     'transform' : 'translate3d(-'+ (slideProgress * - 167) +'px, 0, 0)'
						// });
					}
				}
			},
			// setTransition( speed ) {
			//     const swiper = this;

			//     for ( let i = 0; i < swiper.slides.length; i++ ) {
			//         $(swiper.slides[i]).css('transition', 'all '+ speed + 'ms');
			//         $(swiper.slides[i]).find('.story-box__thumb figure').css('transition', 'all '+ speed + 'ms');
			//     }
			// }
		},
	};
	let flag = true;

	const parallax = () => {
		const $elm = $('.hPara').length > 0 ? $('.hPara') : false;

		$.each($elm, function () {
			const $this = $(this);

			$window.on('load scroll', function () {
				const _st = $window.scrollTop();
				const _view_height = $window.height();
				const _el_offset = $this.offset().top;
				const _el_height = $this.outerHeight(true);
				const _end_offset = _el_offset + _el_height;

				// if( _end_offset < _st + _view_height ) {
				if (_el_offset < _st + _view_height) {
					$this.addClass('fadeInDown');
				} else {
					if (_el_offset >= _st + _view_height) {
						$this.removeClass('fadeInDown');
					}
				}
			});
		});
	};

	const main_scroll = () => {
		const $header = $('.hd__header');

		$window.on('load scroll', function () {
			const _st = $window.scrollTop();
			// if (_st >= 1) {
			// 	$header.css({
			// 		backgroundColor: 'rgba(255, 255, 255, 1)',
			// 		'border-bottom': '1px solid rgba(188, 188, 188, 1)',
			// 	});
			// } else {
			// 	$header.css({
			// 		backgroundColor: 'rgba(255, 255, 255, 0)',
			// 		'border-bottom': '1px solid rgba(188, 188, 188, 0)',
			// 	});
			// }
			// const _header_val = _st * 0.004;

			// $header.css({
			//     'backgroundColor' : 'rgba(255, 255, 255, '+ (_header_val * 0.5) +')',
			//     'border-color' : 'rgba(188, 188, 188, '+ (_header_val * 0.5) +')'
			// });

			// $('.visual-box .swiper-wrapper').css({
			//     'transform': 'translate3d(0, '+ (_st * 0.4) +'px, 0)',
			//     'opacity' : 1 - (_st * 0.0008)
			// })
		});
	};

	const main_slider = {
		mealarea_slider() {
			// 메인 슬라이드 같이 움직이는 경우 아래 주석 해제
			const $target = $('.hd__main__visual .meal-area');
			const $target_visual = $('.hd__main__visual .visual-box');
			// const $slider_visual = new Swiper($target_visual, {
			//     fadeEffect: {
			//         crossFade : true
			//     },
			//     effect: 'fade',
			//     noSwipingSelector: '.hd__main__visual .visual-box',
			// });
			const $slider = new Swiper($target, {
				loop: true,
				width: 640,
				//spaceBetween: 20,
				speed: 1000,
				touchReleaseOnEdges: true,
				// autoplayDisableOnInteraction: false,
				// loopAdditionalSlides: 1,
				// simulateTouch: false,
				// simulateTouch
				// preventInteractionOnTransition: true,
				// loopFillGroupWithBlank: true,
				// touchStartPreventDefault: true,
				// touchStartForcePreventDefault: true,
				// preventInteractionOnTransition: true,
				autoplay: {
					delay: 3500,
					disableOnInteraction: false,
				},
				// pagination: {
				// 	el: '.hd__main__visual .swiper-pagination',
				// 	clickable: false,
				// },
				on: {
					init: updSwiperNumericPagination,
					slideChange: updSwiperNumericPagination
				}
			});

			function updSwiperNumericPagination() {
				this.el.querySelector('.swiper-counter').innerHTML =
					'<span class="count">' + (this.realIndex + 1) + '</span>/<span class="total">' + (this.slides.length-2) + '</span>';
			}
		},
		market_slider() {
			const $target = $('.hd__main__market .swiper-container');
			const $slider = new Swiper($target, {
				spaceBetween: 20,
				width: 450,
				parallax: true,
				loop: true,
				loopAdditionalSlides: 4,
				// autoplay: {
				// 	delay: 3500,
				// 	disableOnInteraction: false,
				// },
				pagination: {
					el: '.hd__main__market .swiper-pagination',
					clickable: false,
				},
			});
		},
		story_slider() {
			const $target = $('.greating-story');
			const $slider = new Swiper($target, story_slider_opts);
		},
		banner_slider() {
			$('.hd__main__banner .swiper-container').each(function () {
				const $target = $(this);
				const $length = $target.find('.swiper-slide').length;
			    
			    if ( $length > 1 ) { 
					const $slider = new Swiper($target, {
						loop: true,
						speed: 1000,
						touchReleaseOnEdges: true,
						autoplay: {
							delay: 3500,
							disableOnInteraction: false,
						},
						width: 640, 
						pagination: {
							el: '.hd__main__banner .swiper-pagination',
							clickable: false,
						},
						navigation: { 
							nextEl: '.swiper-button-next', 
							prevEl: '.swiper-button-prev', 
						},
				    });

				} else{
			    	$target.find("[class^='swiper-button']").hide(); 
			    	$target.find(".swiper-pagination").hide(); 
				}
			});
		},
		meal_slider() {
			const $target = $('.hd__main__care .swiper-container');
			const $slider = new Swiper($target, {
				slidesPerView: 'auto',
				centeredSlides: true,
				loop: true,
				touchReleaseOnEdges: true,
				// pagination: {
    //               el: ".hd__main__care .swiper-container .swiper-pagination",
    //               type: "progressbar",
    //             },
    //             on: {
    //                 init: updSwiperNumericPagination,
    //                 slideChange: updSwiperNumericPagination
    //             }
				
			});

			// function updSwiperNumericPagination() {
   //              var totalSlides = $('.hd__main__care .swiper-container .swiper-slide:not(.swiper-slide-duplicate)').length;

   //              this.el.querySelector('.hd__main__care .swiper-container .swiper-counter').innerHTML =
   //                  '<span class="count">' + (this.realIndex + 1) + '</span>/<span class="total">' + totalSlides + '</span>';
   //          }
		},
		challenge_slider() {
			const $target = $('.hd__main__challenge .swiper-container');
			const $slider = new Swiper($target, {
				slidesPerView: 'auto',
				loop: true,
				touchReleaseOnEdges: true,
				autoplay: {
					delay: 3500,
					disableOnInteraction: false,
				},
				pagination: {
                  el: ".hd__main__challenge .swiper-container .swiper-pagination",
                  type: "progressbar",
                },
                on: {
                    init: updSwiperNumericPagination,
                    slideChange: updSwiperNumericPagination
                }
				
			});

			function updSwiperNumericPagination() {
                var totalSlides = $('.hd__main__challenge .swiper-container .swiper-slide:not(.swiper-slide-duplicate)').length;

                this.el.querySelector('.hd__main__care .swiper-container .swiper-counter, .hd__main__challenge .swiper-container .swiper-counter').innerHTML =
                    '<span class="count">' + (this.realIndex + 1) + '</span>/<span class="total">' + totalSlides + '</span>';
            }
		},
		// product_slider() {
		// 	const $target = $('.hd__main__recomend .swiper-prudect');
		// 	const $slider = new Swiper('.hd__main__recomend .swiper-prudect', {
		// 		slidesPerView: 'auto',
		// 	});
		// },
		brand_slider() {
			const $target = $('.hd__main__brand .swiper-container');
			const $slider = new Swiper($target, {
				slidesPerView: 'auto',
				loop: true,
				touchReleaseOnEdges: true,
				pagination: {
		          el: ".hd__main__brand .swiper-pagination",
		          type: "progressbar",
		        },
				touchReleaseOnEdges: true
			});
		},
		library_slider() {
			const $target = $('.hd__main__library .swiper-container');
			const $slider = new Swiper($target, {
				slidesPerView: 'auto',
				touchReleaseOnEdges: true
			});
		},
		magazine_slider(){
			const $magazineBG = new Swiper('.hd__main__magazine .magazine-bg', {
				effect: "fade"
			});

			const $magazineList = new Swiper('.hd__main__magazine .magazine-list', {
				slidesPerView: 'auto',
				centeredSlides: true,
				loop: true,
				speed: 1000,
				slidesPerView: 'auto',
				pagination: {
					el: '.magazine-list .swiper-pagination',
					clickable: true,
				},
				touchReleaseOnEdges: true,
				thumbs: {
		          swiper: $magazineBG,
		        },
			});
		},
		notice_slider() {
			const $target = $('.hd__main__notice .swiper-container');
			const $slider = new Swiper($target, {
				slidesPerView: 1,
				direction: 'vertical',
				loop: true,
				speed: 1000,
				autoplay: {
					disableOnInteraction: false,
				},
				allowTouchMove : false,
				touchReleaseOnEdges: true
			});
		}
	};

	const teaser = () => {
		$('.teaser-cont a').on('click', function (e) {
			const $this = $(this);
			const _end = $this.data('end');

			if (_end) {
				$this.closest('.teaser-wrap').hide();
			} else {
				$this.closest('.teaser-cont').hide();
			}

			e.preventDefault();
		});

		const $teaser = $('.teaser-wrap');
		const $body = $('.hd');

		if ($teaser.css('display') == 'block') {
			$body.css('overflow', 'hidden');
		}
	};

	const main_init = () => {
		parallax();
		//main_scroll();
		teaser();

		// setTimeout(() => {
		// 	main_slider.mealarea_slider();
		// 	console.log('슬55');
		// }, 1000);
		//main_slider.story_slider();
		
		
		// setTimeout(() => {
		// main_slider.banner_slider();
		// }, 1000);
	};

	main_init();

	window.main_slider = main_slider;
}

export default main;
