/**
 * Created by forbiz on 2019-02-11.
 */


const caulseAgree = () => {
    const $accd = $('.accordion-menu');
    const $accd_list = $accd.find('.accordion-menu__list');

    const checkboxJs = () => {
        const $agree_chkBox = $('[name="caulseAgree"]');
        const $marketing_chkBox = $('[name="marketings"]');
        const $agree_all = $('#allChk');
        const $caulse_chk = $('input[id*="agree"]');

        const caulseAgreeCheck = () => {
            // 전체 약관동의
            $agree_all
                .on('change', function() {
                    const $this = $(this);
                    
                    if ( $this.is(':checked') ) {
                        $agree_chkBox.prop('checked', true).attr('data-checked', 'y');
                        $marketing_chkBox.prop('checked', true).attr('data-checked', 'y');                
                    } else {
                        $agree_chkBox.prop('checked', false).attr('data-checked', 'n');
                        $marketing_chkBox.prop('checked', false).attr('data-checked', 'n');
                    }
                });

            // 개별 항목
            $caulse_chk
                .on('change', function() {
                    const $this = $(this);

                    if ( $this.is(':checked') ) {
                        if ( $caulse_chk.length == $('input[id*="agree"]:checked').length ) {
                            $agree_chkBox.eq(0).prop('checked', true);    
                        }

                        $this.prop('checked', true).attr('data-checked', 'y');
                    } else {
                        $this.prop('checked', false).attr('data-checked', 'n');
                        $agree_chkBox.eq(0).prop('checked', false);
                    }
                });

            // 마케팅 활용 동의
            $caulse_chk.last()
                .on('change', function() {
                    const $this = $(this);

                    if ( $this.is(':checked') ) {
                        $marketing_chkBox.prop('checked', true).attr('data-checked', 'y');
                    } else {
                        $marketing_chkBox.prop('checked', false).attr('data-checked', 'n');
                    }
                })
        };

        const marketingCheck = () => {
            // 마케팅 활용 동의 수신 여부
            // 1개 이상일 경우 체크 O / 없으면 체크 X
            $marketing_chkBox
                .on('change', function() {
                    const $this = $(this);

                    // 지정 checkbox checked 유무
                    if( $this.is(':checked') ) {
                        $this.attr('data-checked', 'y');
                    } else {
                        $this.attr('data-checked', 'n');
                    }

                    // 마케팅 활용 동의 checked 유무
                    if ( $('[name="marketings"]:checked').length == 0 ) {
                        $caulse_chk.last().prop('checked', false).attr('data-checked', 'n');
                        $agree_all.prop('checked', false).attr('data-checked', 'n');
                    } else {
                        $caulse_chk.last().prop('checked', true).attr('data-checked', 'y');

                        if ( $('[id*="agree"]:checked').length == 4 ) {
                            $agree_all.prop('checked', true).attr('data-checked', 'y');
                        } else {
                            $agree_all.prop('checked', false).attr('data-checked', 'n'); 
                        }
                    }
                })
        };

        const checkboxInit = () => {
            caulseAgreeCheck();
            marketingCheck();
        };

        checkboxInit();
    };

    const caulseAgreeAccordion = () => {
        const $btn_detail = $accd.find('.viewMore-btn');

        $btn_detail
            .on('click', function() {
                const $this = $(this);

                // 아코디언 메뉴(1개 이상 못열게 처리)
                if( !$this.closest($accd_list).hasClass('on') ) {
                    $this.closest($accd_list).siblings().removeClass('on').find('.accordion-menu__list__txt').stop().slideUp();
                    $this.closest($accd_list).addClass('on').find('.accordion-menu__list__txt').stop().slideDown();
                } else {
                    $this.closest($accd_list).removeClass('on').find('.accordion-menu__list__txt').stop().slideUp();
                }
            });
    };

    const caulseAgreeInit = () => {
        checkboxJs();
        caulseAgreeAccordion();
        
    };

    caulseAgreeInit();
};

export default caulseAgree;