/**
 * Created by forbiz on 2019-02-11.
 */

const myReview = () => {
    const $document = $(document);

    const myReviewAccd = () => {
        $document
            .on('click', '.myReview .myReview-list__view', function() {
                const $this = $(this);

                if ( !$this.hasClass('on') ) {
                    $this.addClass('on');
                    $this.next().stop().slideDown();
                } else {
                    $this.removeClass('on');
                    $this.next().stop().slideUp();
                } 
            });
    }
    
    const myReviewInit = () => {
        myReviewAccd();
        
    };

    myReviewInit();
};

export default myReview;