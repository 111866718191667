/**
 * Created by Green on 2019-07-04.
 */

import Swiper from 'swiper/dist/js/swiper'

const winnerList = () => {
    
    const winner_slider = () => {

        // Params
        let winnerList_slider = '.px__slider';
        let interleaveOffset = 0.5;

        const display_slider_opts = {
            autoplay: false,                // 자동재생
            grabCursor: true,               // cursor 손모양으로 변경
            loop: false,                    // 반복
            loopAdditionalSlides: 10,       // loop true일 경우 list 복제(왜 있는지 모르겠음)
            parallax: true,
            speed: 1000,                    // 슬라이드 진행 속도
            watchSlidesProgress: true,      // 진행률을 계산

            pagination: {
                el: '.hd__winnerList .swiper-pagination',
                clickable: false,
            },

            on: {
            // init: function() {
            
            // },
            imagesReady: function() {
            this.el.classList.remove('loading');
            },
            // slideChangeTransitionEnd: function() {
            // let swiper = this;
            // },
            progress: function() {
            let swiper = this;
            
            for (let i = 0; i < swiper.slides.length; i++) {
            let slideProgress = swiper.slides[i].progress,
            innerOffset = swiper.width * interleaveOffset,
            innerTranslate = slideProgress * innerOffset;
            
            $(swiper.slides[i]).find('.px__slider__bgimg').css('transform', 'translate3d('+ innerTranslate +'px, 0, 0)');
            }
            },
            touchStart: function() {
            let swiper = this;
            
            for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
            }
            },
            setTransition: function(speed) {
            let swiper = this;
            
            for (let i = 0; i < swiper.slides.length; i++) {
            $(swiper.slides[i]).css('transition', 'all '+ speed + 'ms')
            $(swiper.slides[i]).find('.px__slider__bgimg').css('transition', 'all '+ speed + 'ms')
            }
            }
            }
        };


        const banner_slider = () => {
            const $target = $('.hd__winnerList .winner-banner');
            const $slider = new Swiper($target, display_slider_opts);
            const $pagination = $('.hd__winnerList .swiper-pagination');

            if($target.find('.swiper-slide').length == 1) {
                $pagination.hide();
            }
        }

        banner_slider();

    }

    const winnerList_init = () => {
        winner_slider();
    };

    winnerList_init();

};

export default winnerList;
     
     
     
     
     
     
     
     
     
     
