     /**
 * Created by forbiz on 2019-02-11.
 */

import Swiper from 'swiper/dist/js/swiper';

const myPageMain = () => {
    const $window = $(window);
    const $document = $(document);
    const $htmlbody = $('html, body');
    const $body = $('body');

         
    const mypage_slider = () => {
        const $target = $('.mypage__sec.swiper-container');
        const $slider = new Swiper($target, {
            loop: true,
            speed: 1000,
            spaceBetween: 30,
            touchReleaseOnEdges: true,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.mypage__sec.swiper-container .swiper-pagination',
                clickable: false,
            }
        });
    };

    const myPageMain_init = () => {
        //CommonTab();
    };

    myPageMain_init();

    window.mypage_slider = mypage_slider;
};

export default myPageMain;
     
     
     
     
     
     
     
     
     
     
