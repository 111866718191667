/**
 * Created by Green on 2022-03-16.
 */

import Swiper from 'swiper/dist/js/swiper';

const brandStory = () => {
	const $window = $(window);
	const $document = $(document);

	const parallax = () => {
		const $elm = $('.hPara').length > 0 ? $('.hPara') : false;

		$.each($elm, function () {
			const $this = $(this);

			$window.on('load scroll', function () {
				const _st = $window.scrollTop();
				const _view_height = $window.height();
				const _el_offset = $this.offset().top;
				const _el_height = $this.outerHeight(true);
				const _end_offset = _el_offset + _el_height;
				var $dimmed = $('.main-img__dimmed');

				if (_el_offset < _st + _view_height) {
					var percent = (_st-_el_offset)/($dimmed.height()*0.5);

	                if( percent > 1 ) percent = 1;
	                else if( percent <= 0 ) percent = 0;

	                $dimmed.css( {opacity:percent*0.6} ); 
					
				} else{
					$dimmed.css( {opacity:0.2} ); 
				}
			});
		});
	};


	const brandStory_init = () => {
		parallax();
	};
 
	brandStory_init();
};

export default brandStory;
