/**
 * Created by Green on 2021-03-08.
 */

import Swiper from 'swiper/dist/js/swiper';

const story = () => {
	const $window = $(window);
	const $document = $(document);

	const story_slider = {
		magazine_slider(){
			const $magazineBG = new Swiper('.story-cont.magazine .magazine-bg', {
				effect: "fade",
			});

			const $magazineList = new Swiper('.story-cont.magazine .magazine-list', {
				slidesPerView: 'auto',
				centeredSlides: true,
				loop: true,
				speed: 1000,
				slidesPerView: 'auto',
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				touchReleaseOnEdges: true,
				thumbs: {
		          swiper: $magazineBG,
		        },
			});
		},

		tv_slider() {
			const $target = $('.story-cont.tv .swiper-container');
			const $slider = new Swiper($target, {
				slidesPerView: 'auto',
				loop: true,
				speed: 1000,
				touchReleaseOnEdges: true,
				autoplay: {
					delay: 3500,
					disableOnInteraction: false,
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				}
		    });
		},

		story_slider() {
			const $target = $('.story-slider.swiper-container');
			const $slider = new Swiper($target, {
				slidesPerView: 'auto',
				loop: true,
				speed: 1000,
				touchReleaseOnEdges: true,
				autoplay: {
					delay: 3500,
					disableOnInteraction: false,
				},
				navigation: { 
					nextEl: '.swiper-button-next', 
					prevEl: '.swiper-button-prev', 
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				}
		    });
		},
	};

	const parallax = () => {
		const $elm = $('.hPara').length > 0 ? $('.hPara') : false;

		$.each($elm, function () {
			const $this = $(this);

			$window.on('load scroll', function () {
				const _st = $window.scrollTop();
				const _view_height = $window.height();
				const _el_offset = $this.offset().top;
				const _el_height = $this.outerHeight(true);
				const _end_offset = _el_offset + _el_height;

				// if( _end_offset < _st + _view_height ) {
				if (_el_offset < _st + _view_height) {
					if($this.hasClass('story-scroll')){
						var $ani = $this.data('animation');

						$this.css({'animation': $ani + ' 40s linear infinite'});
					} else{
						$this.addClass('fadeInUp');
					}
				} else {
					if (_el_offset >= _st + _view_height) {
						if($this.hasClass('story-scroll')){
							$this.removeAttr('style');
						} else{
							$this.removeClass('fadeInUp');
						}
					}
				}
			});
		});
	};


	const story_init = () => {
		parallax();
		story_slider.story_slider();
	};
 
	story_init();

	window.story_slider = story_slider;
};

export default story;
