/**
 * Created by forbiz on 2019-02-11.
 */


const businessJoin = () => {
    const $window = $(window);
    const $document = $(document);

    const inputFileLoad = () => {

        const $inputFile = $('.input-box input[type=file]');
        const $fileDeleteBtn = $('.file-delete');

        $inputFile
            .on('change', function() {
                const $this = $(this);
                const $srcInput = $this.closest('.input-box').find('input[type=text]');
                const _thisData = Number($this.data('maxmemory-mb'));
                const _maxSize = ( _thisData * 1024 * 1024 ); // maxSize 1MB 이상
                const files = $this[0].files;
                let _fileSize = 0;

                if ( files.length > 0 ) {
                    _fileSize = $this[0].files[0].size;

                    if ( _fileSize >= _maxSize ) {
                        $this.val('');
                        alert("3MB 미만의 파일만 등록 가능합니다.");
                    } else {
                        $srcInput.val(files[0].name);
                        $this.closest('.input-box').find($fileDeleteBtn).show();
                    }
                } else {
                    $srcInput.val('');
                    $this.closest('.input-box').find($fileDeleteBtn).hide();
                }
            });

        $fileDeleteBtn
            .on('click', function() {
                const $this = $(this);
                const $srcInput = $this.prev('.input_file');
                const $fileInput = $this.next('label').find('input[type=file]');

                $srcInput.val('');
                $fileInput.val('');
                $this.hide();

                return false;
            });
    }

    const businessJoinInit = () => {
        inputFileLoad();
    };

    businessJoinInit();
};

export default businessJoin;