/**
 * Created by Green on 2019-06-27.
 */

const lows_confirm = () => {
    const $document = $(document);
    
    const product_present_event = () => {

        $document
            .on('click', '.meals.gift li', function() {
                const $this = $(this);
                
                if(!$this.hasClass('soldout')){
                    if($this.hasClass('on')){
                        $this.removeClass('on');
                    } else{
                        $this.siblings('li').removeClass('on');
                        $this.addClass('on');
                    }
                }
            });
    };

    
    const confirmCount = () => {
        $document
            .on('click', '.box__list .btn__count-down', function() {
                const $this = $(this);
                const $target = $this.siblings('input');
                const _max_count = Number($target.attr('data-max-count'));

                menuAmount( $this, $target, _max_count );
            })
            .on('click', '.box__list .btn__count-up', function() {
                const $this = $(this);
                const $target = $this.siblings('input');
                const _max_count = Number($target.attr('data-max-count'));
    
                menuAmount( $this, $target, _max_count );
            })
            .on('keyup', '.box__list__count input', function( e ) {
                const $this = $(this);
                const _val = $this.val();
                const _max_count = Number($this.attr('data-max-count'));

                if ( _val === '0' ) {
                    $this.siblings('.btn__count-down').attr('disabled', true);
                    $this.removeClass('on').closest('.box__list__count').siblings('input[type="checkbox"]').prop('checked', false);
                } else if ( _val > _max_count ) {
                    alert('최대수량입니다.');
                    return $this.val(_max_count);
                }
            });

            const menuAmount = ( $button, $input, _maxCount ) => {
                let _val = Number($input.val());

                if ( $button.hasClass('btn__count-down') ) {
                    if ( 1 < _val ) {
                        _val -= 1;
                    } else {
                        $button.attr('disabled', true);
                        $input.removeClass('on').closest('.box__list__count').siblings('input[type="checkbox"]').prop('checked', false);
                        _val = 0;
                    }
                } else {
                    if ( _val < _maxCount ) {
                        $button.siblings('.btn__count-down').attr('disabled', false);
                        $input.addClass('on').closest('.box__list__count').siblings('input[type="checkbox"]').prop('checked', true);
                        _val += 1;
                    } else {
                        alert('최대수량입니다.');
                        _val = _maxCount;
                    }
                }

                $input.val(_val);
            };
    };

    const lows_confirm_init = () => {
        product_present_event();
        confirmCount();
    };

    lows_confirm_init();
}

export default lows_confirm;