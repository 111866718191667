     /**
 * Created by forbiz on 2019-02-11.
 */


const applyOrderExchange = () => {
    const $document = $(document);
         
     // 교환발송방법 클릭이벤트
     const exchangeWay = () => {
        const $wayArea = $('.hd__applyOrderExchange .way-area');
        const $btnByself = $wayArea.find('.byself');
        const $btnVisit = $wayArea.find('.visit');
        const $byselfContents = $('.hd__applyOrderExchange .byself-contents');
        const $visitContents = $('.hd__applyOrderExchange .visit-contents');

        // 직접발송 선택시      
        $btnByself
            .on('click', function() {
                $btnByself.addClass('on');
                $btnVisit.removeClass('on');
                $visitContents.hide();
                $byselfContents.show();
            }); 

        // 지정택배방문요청 선택시 
        $btnVisit
            .on('click', function() {
                $btnVisit.addClass('on');
                $btnByself.removeClass('on');
                $byselfContents.hide();
                $visitContents.show();
            });
    };     

    // 교환발송정보 클릭이벤트
    const exchangeIinfo = () => {
    const $infoArea = $('.hd__applyOrderExchange .info-area');
    const $btnInputYes = $infoArea.find('.inputYes');
    const $btnInputNo = $infoArea.find('.inputNo');
    const $targetCont = $('.hd__applyOrderExchange .byself-contents .select-wrap');

    // 배송업체정보입력 선택시               
    $btnInputYes
        .on('click', function() {
            $btnInputYes.addClass('on');
            $btnInputNo.removeClass('on');
            $targetCont.show();
        }); 

    // 배송업체정보입력 선택시 
    $btnInputNo
        .on('click', function() {
            $btnInputNo.addClass('on');
            $btnInputYes.removeClass('on');
            $targetCont.hide();
        });
    };

    const checkDawnDelivery = () => {
        const $checkArea = $('.hd__applyOrderExchange  .dawnDelivery-info').find('input[name=chkDawnDelivery]');
        const $checkbox = $('.hd__applyOrderExchange .dawnDelivery-info .checks');
        const $target = $('.dawnDelivery-info .infobox');

        $checkArea
        .on("change", function() {
            const $this = $(this);

            if ( $this.prop('checked') == true ) {
                $target.addClass('infobox--show');
                $checkbox.addClass('on');
            } else {
                $target.removeClass('infobox--show');
                $checkbox.removeClass('on');
            }
        })
    };

    // 선불 / 착불
    const deliveryPay = () => {
        $document
            .on('click', '.delivery-btn button', function(e) {
                const $this = $(this);

                $this.addClass('on').siblings().removeClass('on');

                e.preventDefault();
            });
    }

    const normalDelivery = () => {
        
        $document
            .on('change', '[name="VISIT_TYPE"]', function() {
                var $this = $(this);
                
                if ( $this.is(':checked') ) {
                    $this.closest('.check-label').siblings('[type="text"]').hide().val('');
                    $this.closest('.check-label').next('input').show();

                    if ( $('.infobox').find('.alertBox').length > 0 ) {
                        $('.infobox').find('.alertBox').remove();
                    }
                }
            });
                

        $document
            .on('click', '.normal-delivery .checks', function() {
                const $this = $(this);
                const $checkInput = $this.children('input[name=deliveryNormal]');
                const $dawnDeliveryInfo = $('.dawnDelivery-info');
                const $dawnDeliveryDesc = $('.dawnDelivery--desc');
                const $dawnDeliveryDescType2 = $('.dawnDelivery--desc-type2');
                
                if( $checkInput.prop('checked') == true ) {
                    $this.addClass('on');
                    $dawnDeliveryInfo.hide();
                    $dawnDeliveryDesc.hide();
                    $dawnDeliveryDescType2.show();
                } else {
                    $this.removeClass('on');
                    $dawnDeliveryInfo.show();
                    $dawnDeliveryDesc.show();
                    $dawnDeliveryDescType2.hide();
                }
            });
    };

    const applyOrderExchange_init = () => {
        exchangeWay();
        exchangeIinfo();
        checkDawnDelivery();
        deliveryPay();
        normalDelivery();
    };

    applyOrderExchange_init();

};

export default applyOrderExchange;