/**
 * Created by Green on 2021-01-06.
 */

import Swiper from 'swiper/dist/js/swiper'

const eventRoulette = () => {  
    const $window = $(window);
    const $document = $(document);
    const $body = $('body');

    const eventRoulette_banner = () => {
        const $target = $('.roulette__banner');

        const $slider = new Swiper($target, {
            speed: 1000,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
            paginationClickable: true,
            navigation: { 
                nextEl: '.swiper-button-next', 
                prevEl: '.swiper-button-prev', 
            }
        });

        if ( $target.find('.swiper-slide').length <= 1 ) { $target.find("[class^='swiper-button']").hide(); }
    };

    const eventRouletteInit = () => {
        //eventRoulette_banner();
    };

    eventRouletteInit();

    window.eventRoulette_banner = eventRoulette_banner;
};

export default eventRoulette;