/**
 * Created by forbiz on 2019-02-11.
 */

const search = () => {
    const $document = $(document);

    const categoryPick = () => {
        $document
            .on('click', ".cate-area__list", function () {
                const $this = $(this);
                const _categoryVal = $this.closest("input[type=checkbox]");

                _categoryVal.prop("checked", true);
            }
        );
    }

    const categoryReset = () => {
        $document
            .on('click', ".cate-reset-btn", function () {
                const $categoryList = $(".cate-area__list input[type=checkbox]");

                $categoryList.prop("checked", false);
            }
        );
    }

    const searchFocus = () => {
        $(document)
            .on('focus', ".search-area .search-input", function () {
                const $this = $(this);
                $this.attr('placeholder', '검색어를 입력해 주세요.');
            })

            .on('blur', ".search-area .search-input", function () {
                const $this = $(this);
                $this.attr('placeholder', "그리팅 '기프트 카드' 출시!");

            });
    };

    const search_init = () => {
        // categoryPick();
        // categoryReset();
        searchFocus();
    };

    search_init();
}

export default search;