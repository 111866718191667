/**
 * Created by forbiz on 2019-06-12.
 */
const reg = () => {
    const $document = $(document);

    // 비밀번호 (영문 대소문자, 숫자, 특수문자 중 3개 이상 조합하여 8자리 이상)
    // input class="pwInput" 추가
    const pwRule = () => {
    
        $document
            .on('change', ".pwInput", function() {
                const pw = $("input[type=password]").val();
                const pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+~])(?=.*[0-9]).{8,20}/;

                if( !pwType.test(pw) ) {
                    alert("비밀번호 입력 조건에 맞게 입력해 주세요.");
                }
            })
    };

    // 비회원주문결제시 주문비밀번호 (영문 대소문자, 숫자, 특수문자 중 2개 이상 조합하여 6자리 이상)
    // input class="pwInputNomem" 추가
    const pwRuleNomem = () => {
    
        $document
            .on('change', ".pwInputNomem", function() {
                const pw = $("input[type=password]").val();

                const pwcheck1 = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,20}/.test(pw); //영문,숫자
                const pwcheck2 = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+~]).{6,20}/.test(pw); //영문,특수문자
                const pwcheck3 = /^(?=.*[!@#$%^&*()_+~])(?=.*[0-9]).{6,20}/.test(pw); //특수문자,숫자

                if(!(pwcheck1 || pwcheck2 || pwcheck3)) {
                    alert("주문 비밀번호는 입력 조건에 맞게 입력해 주세요.");
                }
            })
    };

    // 이메일 (전체입력)
    // input class="emailCheck" id="BUYER_EMAIL" 추가
    const emailRule = () => {
    
        $document
            .on('change', ".emailCheck", function() {
                const email = $("input[id=BUYER_EMAIL]").val();
                const emailType = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

                if(!emailType.test(email)) {
                    alert("이메일 입력 조건에 맞게 입력해주세요.");
                }
            })
    };

    // 전화번호
    // inputdp attribute "numberOnly" 추가
    const NumberRule = () => {

        $document
            .on('keyup input', "[numberOnly]", function(e) {
                const $this = $(this);
                let _val = $this.val();

                if ( !checkNumber(_val) ) {
                    $this.val(_val.slice(0, -1));                    
                }

                e.preventDefault();
            });

            const checkNumber = str => {
                let flag = true;

                if ( str.length > 0 ) {
                    for ( let i = 0; i < str.length; i++ ) {
                        // if ( str.charAt(0) == '0' ) {
                        //     flag = false;
                        // }

                        if ( str.charAt(i) < '0' || str.charAt(i) > '9' ) {
                            flag = false;
                        }
                    }
                } 

                return flag;
            }
    };

    // 회원가입 폼 스크립트
    const memberJoinCheck = () => {
        // 회원가입 - 이메일 체크
        const emailToggle = () => {
            const $domain = $('.domain__check');
            const $domainChk = $domain.find('input');
            const $mailbox = $('.email__box');

            $domainChk
                .on('change', function() {
                    const $this = $(this);

                    if ( $this.prop('checked') == true ) {
                        $this.attr('data-check', 'y');
                        $mailbox.addClass('on');
                    } else {
                        $this.attr('data-check', 'n');
                        $mailbox.removeClass('on');
                    }
                });
        };

        // 회원가입 - 성별 체크
        const genderToggle = () => {
            const $gender = $('input[name="gender"]');

            $gender
                .on('change', function() {
                    const $this = $(this);

                    if ( $this.prop('checked') == true ) {
                        $gender.attr('data-check', 'n');
                        $this.attr('data-check', 'y');
                    } else {
                        $this.attr('data-check', 'n');
                    }
                });
        };

        // 회원가입 - 건강정보 체크
        const healthToggle = () => {
            const $healthChk = $('.health-info__sec').find('input');

            $healthChk
                .on('change', function() {
                    const $this = $(this);
                
                    if ( $this.prop('checked') == true ) {
                        $this.attr('data-check', 'y');
                    } else {
                        $this.attr('data-check', 'n');
                    }
                })
        };

        const memberJoinCheckInit = () => {
            emailToggle();
            genderToggle();
            healthToggle();
        };

        memberJoinCheckInit();
    };

    const checkboxLoad = () => {
        const $checkbox = $('input[type="checkbox"]');
        const $radio = $('input[type="radio"]');

        if ( $checkbox.length > 0 || $radio.length > 0 ) {
            $.each($radio, function() {
                const $this = $(this);
        
                if( $this.is(':checked') ) $this.attr('data-checked', 'y');
                else $this.attr('data-checked', 'n');
            });

            $.each($checkbox, function() {
                const $this = $(this);
        
                if( $this.is(':checked') ) $this.attr('data-checked', 'y');
                else $this.attr('data-checked', 'n');
            });
        }
    }


    const reg_init = () => {
        // pwRule();
        pwRuleNomem();
        emailRule();
        NumberRule();
        memberJoinCheck();
        checkboxLoad();
    }

    reg_init();

};

export default reg;