/**
 * Created by Green on 2019-06-27.
 */

const marketDesignation = () => {
    const $window = $(window);
    const $document = $(document);
    const $detail_layer = $('.popup__planMeals-detail');
    
    const marketDesignationListCount = () => {

        $document
            .on('click', '.item__count .btn__count-down', function() {
                const $this = $(this);
                const $target = $this.siblings('input');
                const _max_count = Number($target.attr('data-max-count'));

                menuAmount( $this, $target, _max_count );
            })
       
            .on('click', '.item__count .btn__count-up', function() {
                const $this = $(this);
                const $target = $this.siblings('input');
                const _max_count = Number($target.attr('data-max-count'));
    
                menuAmount( $this, $target, _max_count );
            })
            .on('keyup', '.item__count input', function( e ) {
                const $this = $(this);
                const _val = $this.val();
                const _max_count = Number($this.attr('data-max-count'));

                if ( _val === '0' ) {
                    $this.siblings('.btn__count-down').attr('disabled', true);
                    $this.removeClass('on').closest('.mchoice__list__count').siblings('input[type="checkbox"]').prop('checked', false);
                } else if ( _val > _max_count ) {
                    alert('최대수량입니다.');
                    return $this.val(_max_count);
                }
            });

            const numberCheck = ( str ) => {
                let flag = true;

                if( str.length > 0 ) {
                    for ( let i = 0; i < str.length; i++ ) {
                        if ( str.charAt(i) < '0' || str.charAt(i) > '9' ) {
                            flag = false;
                        }
                    }
                }

                return flag;
            }

            const menuAmount = ( $button, $input, _maxCount ) => {
                let _val = Number($input.val());

                if ( $button.hasClass('btn__count-down') ) {
                    if ( 1 < _val ) {
                        _val -= 1;
                    } else {
                        $button.attr('disabled', true);
                        $input.removeClass('on').closest('.mchoice__list__count').siblings('input[type="checkbox"]').prop('checked', false);
                        _val = 0;
                    }
                } else {
                    if ( _val < _maxCount ) {
                        $button.siblings('.btn__count-down').attr('disabled', false);
                        $input.addClass('on').closest('.mchoice__list__count').siblings('input[type="checkbox"]').prop('checked', true);
                        _val += 1;
                    } else {
                        alert('최대수량입니다.');
                        _val = _maxCount;
                    }
                }

                $input.val(_val);
            };
    };

    const marketDesignationDetail = () => {
        const $close = $detail_layer.find('.close-area a');
        let fisrt_st = 0;

        $document
            .on('click', '.detail-open', function(e) {
                fisrt_st = $window.scrollTop();

                $('body').css('paddingBottom', 0);
                $('.hd__header').hide();
                $('.hd__marketDesignation').hide();
                $('.hd__footer').hide();
                $detail_layer.show();
                $window.scrollTop(0);

                e.preventDefault();
            });

        $close
            .on('click', function(e) {
                $('body').css('paddingBottom', '85px');
                $('.hd__header').show();
                $('.hd__marketDesignation').show();
                $('.hd__footer').show();
                $detail_layer.hide();
                
                setTimeout(function() {
                    $window.scrollTop(fisrt_st);
                }, 0);

                e.preventDefault();
            });
    };

    const marketDesignationInit = () => {
        marketDesignationListCount();
        // marketDesignationDetail();
    };

    marketDesignationInit();
};

export default marketDesignation;