     /**
 * Created by forbiz on 2019-02-11.
 */


const mypage = () => {
    const $window = $(window);
    const $document = $(document);
    const $htmlbody = $('html, body');
    const $body = $('body');

         
     // 상세 대메뉴 탭 Event
     const CommonTab = () => {
         
        $document
            .on('click', ".tab__area li", function() {
                const $this = $(this);
                const $tnb_area = $this.closest('.tab-list');
                
                const tab_name = $this.data('tab');
                const $target = $('#' + tab_name);

                $this.addClass('on').siblings().removeClass('on');
                $target.addClass('on').siblings().removeClass('on');


                return false;
            });
    };

    const mypage_init = () => {
        //CommonTab();
    };

    mypage_init();

};

export default mypage;
     
     
     
     
     
     
     
     
     
     
