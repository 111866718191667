/**
 * Created by forbiz on 2019-02-11.
 */

const ecoDelivery = () => {
    const $document = $(document);

    $document
        .on('click', '.viewMore-btn', function() {
            const $this = $(this);

            // 아코디언 메뉴(1개 이상 못열게 처리)
            if( !$this.closest('.accordion-menu__list').hasClass('on') ) {
                $this.closest('.accordion-menu__list').siblings().removeClass('on').find('.accordion-menu__list__txt').stop().slideUp();
                $this.closest('.accordion-menu__list').addClass('on').find('.accordion-menu__list__txt').stop().slideDown();
            } else {
                $this.closest('.accordion-menu__list').removeClass('on').find('.accordion-menu__list__txt').stop().slideUp();
            }
        });
};

export default ecoDelivery;