/**
 * Created by forbiz on 2019-02-11.
 */

const myOrderList = () => {
    const $document = $(document);

    const lowsMchoiceListCount = () => {
        $document
            .on('click', '.mchoice__list .btn__count-down', function() {
                const $this = $(this);
                const $target = $this.siblings('input');
                const _max_count = Number($target.attr('data-max-count'));

                menuAmount( $this, $target, _max_count );
            })
    
            .on('click', '.mchoice__list .btn__count-up', function() {
                const $this = $(this);
                const $target = $this.siblings('input');
                const _max_count = Number($target.attr('data-max-count'));

                menuAmount( $this, $target, _max_count );
            })
            .on('keyup', '.mchoice__list__count input', function( e ) {
                const $this = $(this);
                const _val = $this.val();
                const _max_count = Number($this.attr('data-max-count'));

                if ( _val === '0' ) {
                    $this.siblings('.btn__count-down').attr('disabled', true);
                    $this.removeClass('on').closest('.mchoice__list__count').siblings('input[type="checkbox"]').prop('checked', false);
                } else if ( _val > _max_count ) {
                    alert('최대수량입니다.');
                    return $this.val(_max_count);
                }
            });

            const menuAmount = ( $button, $input, _maxCount ) => {
                let _val = Number($input.val());

                if ( $button.hasClass('btn__count-down') ) {
                    if ( 1 < _val ) {
                        _val -= 1;
                    } else {
                        $button.attr('disabled', true);
                        $input.removeClass('on').closest('.mchoice__list__count').siblings('input[type="checkbox"]').prop('checked', false);
                        _val = 0;
                    }
                } else {
                    if ( _val < _maxCount ) {
                        $button.siblings('.btn__count-down').attr('disabled', false);
                        $input.addClass('on').closest('.mchoice__list__count').siblings('input[type="checkbox"]').prop('checked', true);
                        _val += 1;
                    } else {
                        alert('최대수량입니다.');
                        _val = _maxCount;
                    }
                }

                $input.val(_val);
            };
    };

    const view_more = () => {
        $document.on('click', '.exchange__view__btn', function() {
            const $this = $(this);

            if ( !$this.hasClass('on') )  $this.addClass('on').siblings('.exchange__view__sec').stop().slideDown();
            else $this.removeClass('on').siblings('.exchange__view__sec').stop().slideUp();
        });
    };

    const myOrderList_init = () => {
        lowsMchoiceListCount();
        view_more();
    };

    myOrderList_init();
}

export default myOrderList;