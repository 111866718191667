/**
 * Created by Green on 2019-07-04.
 */

import Swiper from 'swiper/dist/js/swiper'

const layout = () => {
    const $window = $(window);
    const $document = $(document);
    const $htmlbody = $('html, body');
    const $body = $('body');

    const scroll_event = () => {
        let st_prev = 0;    // 위 방향 스크롤링 계산하기 위해 만든 상수

        $window.on('scroll', function () {
            const _st = $window.scrollTop();
            const $headerArea = $('.hd__header');
            const $target = $('.hd__btn-go-top, .hd__btn-gr-value');
            const $title_area = $('.hd__page-title');
            const $marketCategory = $('.hd__market__cate-wrap');
            const $marketCategory2 = $('.hd__market .category .category__depth2');
            const $marketCategory3 = $('.hd__market .category .category__depth3');
            const _end = $(document).height() - $(window).height() -1;

            if( $body.attr('id').indexOf('planMealsDetail') != 0 && !$body.hasClass('hd--fixed') ) {

                // 아래 방향으로 스크롤 한 경우
                if( st_prev < _st && _st > 0 ) {     // st_prev < _st : 스크롤한 값이 이전 값보다 크면 아래 방향 스크롤링
                    $body.addClass('docbar--hide');
                    $target.addClass("show").removeClass('move');
                    $marketCategory.addClass('hd__market__cate-wrap--move');

                    // 스크롤 위치가 최하단에 있는 경우
                    if(_end == _st || _end - _st <= 200) {
                        $body.addClass('docbar--hide');
                        $target.removeClass("show");
                    }

                    if( $body.attr('id').indexOf('main') != 0 ) {
                        $headerArea.addClass('hd__header__st-event');

                        if($marketCategory3.length >= 1){
                            if( $marketCategory2.length != 1 ){
                                $marketCategory3.closest('.category').addClass('category--move');
                            }
                        }
                    } 

                    if( $marketCategory2.length >= 1 ){

                        if (!$('.hd__market .category .category__depth2').hasClass('v2')) {
                            $('.hd__header').css({
                                'position': 'absolute',    
                                'transform': 'none'
                            });
                            
                            if( $marketCategory2.offset().top - 60 <= _st){
                                $marketCategory2.closest('.category').addClass('category--move');
                            }
                        }
                        
                    } 

                // 스크롤을 하지 않은 경우 ( st_prev > _st : 스크롤한 값이 이전 값보다 크면 위 방향 스크롤링 )
                } else if( _st <= 0 ) {
                    $target.removeClass('show');
                    $headerArea.removeClass('hd__header__st-event');
                    $marketCategory.removeClass('hd__market__cate-wrap--move');
                    $marketCategory2.removeClass('category__depth2--move');
                    $marketCategory2.closest('.category').removeClass('category--move');
                    $marketCategory3.closest('.category').removeClass('category--move');

                // 위 방향으로 스크롤 한 경우
                }  else {
                    $body.removeClass('docbar--hide');
                    $target.addClass('show');

                    // 스크롤 위치가 최하단에 있는 경우
                    if(_end == _st || _end - _st <= 90) {
                        $body.addClass('docbar--hide');
                        $target.removeClass("show");
                    }

                    if( $marketCategory2.length >= 1 ){
                        if( $marketCategory2.offset().top >= _st){
                            $marketCategory2.closest('.category').removeClass('category--move');
                        }
                    } else if($marketCategory3.length >= 1){
                        if( $marketCategory3.offset().top >= _st){
                            $marketCategory3.closest('.category').removeClass('category--move');
                        }
                    }
                }

            }

            st_prev = _st;
        });

        $document.on("click", ".hd__btn-go-top", function () {
            $htmlbody.stop().animate({scrollTop:0});
            return false;
        });

    }

    // const docbar = () => {
    //     $('.hd__nav-area a')
    //         .each(function() {
    //             const $this = $(this);
    //             const targetURL = $this.attr('href').split('/');
    //             let pageURL = location.pathname.split('/');
                
    //             if ( pageURL[1].toLowerCase() == 'index' ) {
    //                 pageURL[1] = '';
    //             }
                
    //             if ( targetURL[1] == pageURL[1] ) {
    //                 $this.addClass('hd__nav-active');
    //             }
    //         });
    // };

    const menu_dropDwon = () => {

        $document
            .on('click', ".hd__page-title__dropdown__txt", function() {
                const $target = $('.hd__page-title__dropdown');
                const $dim = $('.hd__market__dim');

                $target.toggleClass('hd__page-title__dropdown--show');
                $dim.toggleClass('hd__market__dim--show');
            });
    };

    const fullmenu = () => {
        const $fullmenuArea = $('.hd__fullmenu'); 
        let _st = 0;
        
        $document
            .on('click', ".hd__header__menu-icon", function(e) {
                _st = $window.scrollTop();
                $body.addClass('hd--fixed');
                $fullmenuArea.addClass('hd__fullmenu--show').css({'height': $window.height()});

                e.preventDefault();
            })
            .on('click', ".btn-close", function(e) {
                $body.removeClass('hd--fixed');
                $fullmenuArea.removeClass('hd__fullmenu--show').css({'height': ''});
                $window.scrollTop(_st);

                e.preventDefault();
            });
    };

    const commLayer = () => {
        const $detail_layer = $('.popup__planMeals-detail');
        const $close = $detail_layer.find('.close-area a');
        let fisrt_st = 0;

        $document
            .on('click', '.comm__layer', function(e) {
                fisrt_st = $window.scrollTop();

                $('body').css('paddingBottom', 0);
                $('.hd__header').hide();
                $('.comm__layer__sec').hide();
                $('.hd__footer').hide();
                $detail_layer.show();
                $window.scrollTop(0);

                e.preventDefault();
            });

        $close
            .on('click', function(e) {
                $('body').css('paddingBottom', '85px');
                $('.hd__header').show();
                $('.comm__layer__sec').show();
                $('.hd__footer').show();
                $detail_layer.hide();
                
                setTimeout(function() {
                    $window.scrollTop(fisrt_st);
                }, 0);

                e.preventDefault();
            });
    };

    const commLayerOpen = () => {
        let fisrt_st = 0;

        $document
            .on('click', '.comm__layer-open', function(e) {
                const $layerName = $(this).data('layer-name');
                const $close = $($layerName).find('.comm__layer-close');

                fisrt_st = $window.scrollTop();

                $('body').css('paddingBottom', 0);
                $('.hd__header').hide();
                $('.comm__layer__sec').hide();
                $('.hd__footer').hide();
                $($layerName).show();
                $window.scrollTop(0);

                e.preventDefault();
                
                $close
                    .on('click', function(e) {
                        $('body').css('paddingBottom', '85px');
                        $('.hd__header').show();
                        $('.comm__layer__sec').show();
                        $('.hd__footer').show();
                        $($layerName).hide();
                        
                        setTimeout(function() {
                            $window.scrollTop(fisrt_st);
                        }, 0);

                        e.preventDefault();
                    });
            });

    };

    // footer 사업자정보확인 팝업 이벤트
    const windowPopup = () => {
        const $winOpen = $('#footer .winOpen');
        const $close = $('.window-popup .btn__close');

        $winOpen
            .on('click', function(e) {
                const $this = $(this);
                const _url = $this.data('url');
                const options = 'left=0, top=0, width=800, height=1200, resizeble=no, scrollbars=yes, status=no';
                window.open(_url, '_blank', options);

                e.preventDefault();
            });

        $close
            .on('click', function(e) {
                window.close();

                e.preventDefault();
            });
    };

    
    // 튜토리얼 열기/닫기/슬라이드 이벤트
    const tutorials = () => {
        const $tutorial = $('.tutorial');
        const $target = $tutorial.find('.tutorial-wrap');

        const $slider = new Swiper($target, {
            grabCursor: true,
            paginationClickable: true,
            resistance : true,
            resistanceRatio : 0,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        
        $tutorial.css({
            "display": "none"
        });

        const open_tutorials = () => {
            $('.hd__btn-tutorial').on('click', function(e) {
                $tutorial.css({
                    "display": "block"
                });
    
                e.stopPropagation();
            });
        };

        const close_tutorials = () => {
            $('.tutorial-close').on('click', function(e) {
                $tutorial.css({
                    "display": "none"
                });
    
                e.stopPropagation();
            });
        };

        open_tutorials();
        close_tutorials();     
    };

    // gnb swiper
    const gnbSwiper = () => {
        const $target = $('.top-menu');
        const swiperGnb = new Swiper($target, {
            slidesPerView: 'auto'
        });

        $('.top-menu__list').each(function(){
            const locationUrl = window.location.href;
            if (locationUrl.indexOf($(this).attr('data-url')) > -1) {
                $(this).addClass('top-menu__list-on');
            }
        });

        const $gnbIdx = $target.find('.top-menu__list-on').index();

        if ($gnbIdx > 4) {
            swiperGnb.slideTo($gnbIdx-1, 0);
        }

    };
    
    const layout_init = () => {
        scroll_event();
        //docbar();
        menu_dropDwon();
        fullmenu();
        commLayer();
        commLayerOpen();
        windowPopup();
        tutorials();
        gnbSwiper();
    };

    layout_init();
}

export default layout;