/**
 * Created by Green on 2019-07-04.
 */

import Swiper from 'swiper/dist/js/swiper'

const greatingLifeDetail = () => {

    const RelationProduct = () => {
        const $relationSwiper = $('.relation-product');
        const $slider = new Swiper($relationSwiper, {
            calculateHeight: true,
            spaceBetween: 28,
            slidesPerView: 2,
            slidesPerGroup: 2,
            pagination: {
                el: '.relation-product .swiper-pagination',
                clickable: true,
            }
        });

        if ( $('.swiper-slide').length < 1 ) {
            $('.swiper-pagination').hide();
        }
    };

    const greatingLifeDetailInit = () => {
        RelationProduct();
    };

    greatingLifeDetailInit();
};

export default greatingLifeDetail;