/**
 * Created by forbiz on 2019-02-11.
 */

const myOrderDetail = () => {
    const $document = $(document);

    const checkDawnDelivery = () => {
        const $checkArea = $('.hd__addAddress  .dawnDelivery-info').find('input[name=chkDawnDelivery]');
        const $checkbox = $('.hd__addAddress .dawnDelivery-info .checks');
        const $target = $('.dawnDelivery-info .infobox');

        $checkArea
        .on("change", function() {
            const $this = $(this);
            if( $this.prop('checked') == true ){
                $target.addClass('infobox--show');
                $checkbox.addClass('on');
            } else {
                $target.removeClass('infobox--show');
                $checkbox.removeClass('on');
            }
        })
    }

    const calendarJs = () => {
        const $tbody = $('.cal_day');
        const fix_year = parseInt( '2019', 10 );
        const fix_month = parseInt( '6', 10 );
        const fix_day = parseInt( '1', 10 );
        const fix_today = new Date( fix_year, fix_month-1, fix_day ); // 오늘 고정 일자
        let year = fix_year; // 년 (변동데이터) 
        let month = fix_month; // 월 (변동데이터)
        let today = fix_today.getDate(); // 오늘 일자(변동데이터)
        let week_today = fix_today.getDay(); // 오늘 요일(변동데이터)
        let first_day = new Date( year, month-1, 1 ); // 해당 년, 월 1일 (변동데이터)
        let last_day = new Date( year, month, 0 ); // 해당 년, 월 마지막일 (변동데이터)
        let str = '';
        
        const calendarBuild = () => {        
            for ( let rows = 0; rows < 6 ; rows ++ ) {
                str += '<tr>';
    
                for ( let cols = 0; cols < 7; cols++ ) {
    
                    if ( cols < week_today && rows == 0 ) {  
                        if ( first_day.getDate() - week_today + cols < 1 ) {
                            calendarOutput( year, month, today, false );
                            
                        } else {
                            today = first_day.getDate() - week_today + cols;
                        }
                        
                    } else {
                        if ( today <= last_day.getDate() ) {
                            calendarOutput( year, month, today, true );
                            today++;
                        } else {
                        }
                    }
                }
                
                str += '</tr>';
            }
            
            $tbody.append(str);
        };
    
        const calendarOutput = ( year, month, date, disable ) => {
            month = month < 10 ? `0${month}` : month;
            var new_date = date < 10 ? '0' + date : date;
    
            if( !disable ) {
                str += `<td class="cal__day cal__disabled">`;
                str += '&nbsp;';
            } else {
                str += `<td class="cal__day">`;
                str += `<input type="checkbox" name="delivery_day" id="day_${year}${month}${new_date}" value="${year}${month}${new_date}">`;
                str += `<label for="day_${year}${month}${new_date}">${date}</label>`;
            }
            
            str += `</td>`;
    
        };

        const calendarInit = () => {
            calendarBuild();
        };
    
        calendarInit();
    };

    // 주문배송결제(정기배송) 이중팝업 이벤트처리
    const overlayPopup = () => {
        const $firstPop = $('.first-pop')
        const $firstPopBtn = $firstPop.find('.btn-next');

        $firstPopBtn
            .on('click', function() {
                $firstPop.closest('.hd__popup').removeClass('popup__modal-open');
            })
    };

    var filePreview = function filePreview() {
        var $inputFile = $('.image-box input[type=file]');
        var $btn_del = $('.del'); 
        
        $inputFile
            .on('change', function(e) {
                var $this = $(this);
                var _thisData = Number($this.data('maxmemory-mb'));
                var _maxSize = ( _thisData * 1024 * 1024 ); // maxSize 1MB 이상
                var files = $this[0].files;
                var _fileSize = 0;
                
                if ( files && files[0] ) {
                    
                    var reader = new FileReader();
                    
                    _fileSize = files[0].size;
                    
                    if ( _fileSize >= _maxSize ) {
                        $this.val('');
                        alert("30MB 미만의 파일만 등록 가능합니다.");
                    } else {
                        $this.siblings('.del').show();
                        
                        reader.onload = function (e) {
                            $this.next('img').attr('src', e.target.result);
                        };
                    }				        

                    reader.readAsDataURL(files[0]);
                } else {
                    $this.val('')
                    $this.next('img').attr('src', '/front_mo/images/img_comm_preview.png');
                }
            });
        
        $btn_del
            .on('click', function() {
                var $this = $(this);
                
                $this.siblings('img').attr('src', '/front_mo/images/img_comm_preview.png').prev('input[type="file"]').val('');
                $this.hide();
            });
    };

    const myOrderDetail_init = () => {
        checkDawnDelivery();
        calendarJs();
        // lowsMchoiceListCount();
        overlayPopup();
        filePreview();
    };

    myOrderDetail_init();
}

export default myOrderDetail;