/**
 * Created by Green on 2019-07-04.
 */

import Swiper from 'swiper/dist/js/swiper'

const healthCare = () => {

    const healthCareSlider = () => {
        const $healthBanner = $('.healthCare__visual__banner .swiper-container');
        new Swiper($healthBanner, {
            spaceBetween: 20,
            width: 450,
            pagination: {
                el: '.swiper-pagination',
                clickable: false,
            },
        });
    };
  
    const healthCareInit = () => {
        healthCareSlider();
    };

    healthCareInit();
};

export default healthCare;