/**
 * Created by Green on 2019-06-27.
 */

const greatingTableDetail = () => {
    const $document = $(document);

    const likeClickEvent = () => {
        $document
            .on('click', '.greatingTable-Detail__btn-like', function() {
                const $this = $(this);
                
                if ( !$this.hasClass('on') ) {
                    $this.addClass('on');
                } else {
                    $this.removeClass('on');
                }
            });
    };
    
    const timerJs = () => {
        const $target = $document.find('.greatingTable-Detail__timer__count');
        const $timerBtn = $('.greatingTable-Detail__timer__btn');
        const $timeData = Number($target.data('timer'));
        let _t = $timeData;
        let timer;
        

        const timerJsSet = ( time ) => {
            let hour;
            let min;
            let sec;

            hour = Math.floor(time / 3600);
            min = Math.floor((time - (hour * 3600)) / 60);
            sec = time - (hour * 3600) - (min * 60);

            if ( hour < 10 ) hour = '0' + hour;
            if ( min < 10 ) min = '0' + min;
            if ( sec < 10 ) sec = '0' + sec;

            return ( hour + ':' + min + ':' + sec);
        };

        const timerEvent = () => {
            $target.text(timerJsSet($timeData));

            $document
                .on('click', '.greatingTable-Detail__timer__btn', function() {
                    const $this = $(this);

                    if ( !$this.hasClass('stop') ) {
                        $this.addClass('stop');

                        timer = setInterval(function() {
                            if ( _t <= 0 ) {
                                alert('시간이 종료되었습니다.');
                                clearInterval(timer);
                                $this.removeClass('stop');
                                $target.text(timerJsSet($timeData));
                                
                                _t = $timeData;
                            } else {
                                _t -= 1;    
                            }
                            
                            $target.text(timerJsSet(_t));
                        }, 1000);
                    } else {
                        clearInterval(timer);
                        $this.removeClass('stop');
                    }
                });
        };

        const timerJsInit = () => {
            timerEvent();
        };

        timerJsInit();
    };
    

    const greatingTableDetailInit = () => {
        // likeClickEvent();
        // timerJs();
    };

    greatingTableDetailInit();
};

export default greatingTableDetail;