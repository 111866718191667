/**
 * Created by Green on 2020-03-30.
 */

import Swiper from 'swiper/dist/js/swiper'

const market = () => {
	const $window = $(window);
    const $document = $(document);    

    const market_slider = {
    	banner() {
			const $target = $('.hd__market__banner');

			if ($target.find('.swiper-slide').length > 1) {
				$target.append('<div class="swiper-pagination"></div>');
				
				const $slider = new Swiper($target, {
					autoplay: {
						delay: 3500,
						autoplayDisableOnInteraction: false,
					},
					slidesPerView: 1,
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
				});
			}
		
		},

		category1() {
			const $target = $('.hd__market .category__depth1.v2 .swiper-container');
			const $slider = new Swiper($target, {
				slidesPerView: 'auto',
				loop: true
			});

			const $index = $target.find('.active').index();
			$slider.slideTo($index-1,0);
		},

		category2() {
			const $target = $('.hd__market .category__depth2 .swiper-container');
			const $slider = new Swiper($target, {
				slidesPerView: 'auto',
			});

			const $index = $target.find('.active').index();
			$slider.slideTo($index-1,0);
		},

		category3() {
			const $target = $('.hd__market .category__depth3 .swiper-container');
			const $slider = new Swiper($target, {
				slidesPerView: 'auto',
			});

			const $index = $target.find('.active').index();
			$slider.slideTo($index-1,0);
		},

		brandBanner(){
			const $target = $('.brand-banner.swiper-container');

			if($target.find('.swiper-slide').length > 1){
	            const $slider = new Swiper($target, {
	                loop: false,
	                loopAdditionalSlides: 10,
	                speed: 1000,
	                touchReleaseOnEdges: true,
	                autoplay: {
	                    delay: 3500,
	                    disableOnInteraction: false,
	                },
	                pagination: {
	                  el: ".brand-banner.swiper-container .swiper-pagination",
	                  type: "progressbar",
	                }
	            });
			}

		}
    };

    const parallax = () => {
		const $elm = $('.hPara').length > 0 ? $('.hPara') : false;

		$.each($elm, function () {
			const $this = $(this);

			$window.on('load scroll', function () {
				const _st = $window.scrollTop();
				const _view_height = $window.height();
				const _el_offset = $this.offset().top;
				const _el_height = $this.outerHeight(true);
				const _end_offset = _el_offset + _el_height;

				// if( _end_offset < _st + _view_height ) {
				if (_el_offset < _st + _view_height) {
					$this.addClass('fadeInUp');
				} else {
					if (_el_offset >= _st + _view_height) {
						$this.removeClass('fadeInUp');
					}
				}
			});
		});
	};

    const market_init = () => {
    	//parallax();
    };

    market_init();

    window.market_slider = market_slider;
    window.parallax = parallax;
}

export default market;


