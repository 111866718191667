/**
 * Created by Green on 2019-07-04.
 */

import Swiper from 'swiper/dist/js/swiper'

const bestList = () => {
    
    const best_slider = () => {
        // Params
        let best_slider = '.px__slider';
        let interleaveOffset = 0.5;

        const best_slider_opts = {
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            }, // 자동재생
            grabCursor: true, // cursor 손모양으로 변경
            loop: true, // 반복
            //loopAdditionalSlides: 10, // loop true일 경우 list 복제(왜 있는지 모르겠음)
            parallax: true,
            speed: 1000, // 슬라이드 진행 속도
            watchSlidesProgress: true, // 진행률을 계산
            pagination: {
                el: '.hd__bestList .swiper-pagination',
                clickable: false
            },
            on: {
                imagesReady: function () {
                    this.el.classList.remove('loading');
                },
                progress: function () {
                    let swiper = this;

                    for (let i = 0; i < swiper.slides.length; i++) {
                        let slideProgress = swiper.slides[i].progress,
                            innerOffset = swiper.width * interleaveOffset,
                            innerTranslate = slideProgress * innerOffset;

                        $(swiper.slides[i]).find('.px__slider__bgimg').css('transform', 'translate3d(' + innerTranslate + 'px, 0, 0)');
                    }
                },
                touchStart: function () {
                    let swiper = this;

                    for (let i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = "";
                    }
                },
                setTransition: function (speed) {
                    let swiper = this;

                    for (let i = 0; i < swiper.slides.length; i++) {
                        $(swiper.slides[i]).css('transition', 'all ' + speed + 'ms');
                        $(swiper.slides[i]).find('.px__slider__bgimg').css('transition', 'all ' + speed + 'ms');
                    }
                }
            }
        };

        const banner_slider = () => {
            const $target = $('.hd__bestList .banner-thumb');
            //const $slider = new Swiper($target, best_slider_opts);
            const $pagination = $('.hd__bestList .swiper-pagination');

            if ($target.find('.swiper-slide').length == 1) {
                $pagination.hide();
            } else {
                new Swiper($target, best_slider_opts)
            }
        }

        const bestmenu_slider = () => {
            const $target = $('.hd__bestList__cate');
            const $slider = new Swiper($target, {
                slidesPerView: 'auto',
                navigation: {
                    nextEl: '.best-btn-next'
                }
            });
        }

        banner_slider();
        bestmenu_slider();
    }

    const bestList_init = () => {
        best_slider();
    };

    bestList_init();

};

export default bestList;
